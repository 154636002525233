import {
  AppointmentTypes,
  AuthDto,
  AvailabilityTypes,
  CalendarEventTypes,
  LocationTypes,
  LoyaltyTypes,
  PaymentDto,
  PromoCodeTypes,
  StoreTypes,
  UserTypes,
} from 'common';

import { LoginWithVerificationTokenOutputBoundary } from 'application/modules/auth/useCases/hooks/useCaseVerifyUser';
import { BookAppointmentValues } from 'application/modules/bookingWizard/useCases/hooks/useCaseConfirmReservation';

import baseApiWithReauth from './baseApiWithReauth';
import {
  AppointmentRoutes,
  AuthRoutes,
  EventRoutes,
  FoodOrderRoutes,
  LocationRoutes,
  ScheduleRoutes,
} from './mboApiRoutes';

const mboAPI = baseApiWithReauth
  .enhanceEndpoints({
    addTagTypes: [
      'User',
      'ClientAppointments',
      'SimulatorAppointments',
      'SimulatorAppointmentsDates',
      'LessonAppointments',
      'ClubFittingAppointments',
      'FirstLessonAppointments',
      'LessonAppointmentsDates',
      'ClubFittingAppointmentsDates',
      'AppointmentPricing',
      'FoodItemList',
      'GiftCards',
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSimulatorLocations: builder.query<Array<LocationTypes.SimulatorLocation>, void>({
        query: () => LocationRoutes.getLocations(),
      }),
      getAvailableSimulatorRentalAppointments: builder.query<
        Array<AvailabilityTypes.SimulatorAppointmentAvailability>,
        {
          locationId: string;
          startDateTime?: string;
          endDateTime?: string;
          email?: string;
          partySize: string;
        }
      >({
        query: ({ locationId, startDateTime, endDateTime, email, partySize }) =>
          AppointmentRoutes.getAvailableSimulatorRentalAppointments(
            locationId,
            partySize,
            startDateTime,
            endDateTime,
            email,
          ),
        providesTags: ['SimulatorAppointments'],
      }),
      getAvailableSimulatorRentalDates: builder.query<
        Array<string>,
        { locationId: string; startDateTime?: string; endDateTime?: string }
      >({
        query: ({ locationId, startDateTime, endDateTime }) =>
          AppointmentRoutes.getAvailableSimulatorRentalDates(
            locationId,
            startDateTime,
            endDateTime,
          ),
        providesTags: ['SimulatorAppointmentsDates'],
      }),
      getAppointmentPricing: builder.mutation<
        AppointmentTypes.ServicePricing[],
        AppointmentTypes.AppointmentPricingRequest
      >({
        query: (body) => ({
          url: AppointmentRoutes.getAppointmentPricing(),
          method: 'POST',
          body,
        }),
      }),
      getClientAppointments: builder.query<AppointmentTypes.ClientAppointmentResponse[], void>({
        query: () => AppointmentRoutes.getClientAppointments(),
        providesTags: ['ClientAppointments'],
      }),
      getUpcomingAppointments: builder.query<AppointmentTypes.ClientAppointmentResponse[], void>({
        query: () => AppointmentRoutes.getUpcomingAppointments(),
      }),
      getHourlySimulatorPricing: builder.mutation<
        AppointmentTypes.HourlySimulatorPricingDto,
        AppointmentTypes.HourlySimulatorPricingRequestDto
      >({
        query: (body) => ({
          url: AppointmentRoutes.getHourlySimulatorPricing(),
          method: 'POST',
          body,
        }),
      }),
      cancelAppointment: builder.mutation<
        AppointmentTypes.CanceledAppointmentResponse,
        { locationId: string; body: AppointmentTypes.CancelAppointmentRequest }
      >({
        query: ({ locationId, body }) => ({
          url: AppointmentRoutes.cancelAppointment(locationId),
          method: 'POST',
          body,
        }),
        invalidatesTags: ['ClientAppointments'],
      }),
      getAvailableFirstTimeLessonsAppointments: builder.query<
        Array<AvailabilityTypes.FirstTimeLessonAppointmentAvailability>,
        { locationId: string; startDateTime?: string; endDateTime?: string; email?: string }
      >({
        query: ({ locationId, startDateTime, endDateTime, email }) =>
          AppointmentRoutes.getAvailableFirstLessonAppointments(
            locationId,
            startDateTime,
            endDateTime,
            email,
          ),
        providesTags: ['FirstLessonAppointments'],
      }),
      getAvailableLessonAppointments: builder.query<
        Array<AvailabilityTypes.LessonAppointmentAvailability>,
        {
          locationId: string;
          startDateTime?: string;
          endDateTime?: string;
          staffIds?: number;
          email?: string;
        }
      >({
        query: ({ locationId, startDateTime, endDateTime, staffIds, email }) =>
          AppointmentRoutes.getAvailableLessonAppointments(
            locationId,
            startDateTime,
            endDateTime,
            staffIds,
            email,
          ),
        providesTags: ['LessonAppointments'],
      }),
      getAvailableClubFittingAppointments: builder.query<
        Array<AvailabilityTypes.ClubFittingAppointmentAvailability>,
        {
          locationId: string;
          startDateTime: string;
          endDateTime: string;
          clubFittingId: number;
          email?: string;
        }
      >({
        query: ({ locationId, startDateTime, endDateTime, clubFittingId, email }) =>
          AppointmentRoutes.getAvailableClubFittingAppointments(
            locationId,
            startDateTime,
            endDateTime,
            clubFittingId,
            email,
          ),
        providesTags: ['ClubFittingAppointments'],
      }),
      getClubFittingTypes: builder.query<AvailabilityTypes.ClubFittingType[], string>({
        query: (locationId) => AppointmentRoutes.getClubFittingTypes(locationId),
      }),
      verifyPromoCode: builder.query<
        PromoCodeTypes.PromoCodeVerificationResponse,
        { siteId: string; promoCode: string; date: string }
      >({
        query: ({ siteId, promoCode, date }) =>
          AppointmentRoutes.verifyPromoCode(siteId, promoCode, date),
      }),
      getDailyPromoCode: builder.query<
        PromoCodeTypes.DailyPromoCodeResponse,
        { siteId: string; date: string }
      >({
        query: ({ siteId, date }) => AppointmentRoutes.getDailyPromoCode(siteId, date),
      }),
      getAvailableLessonDates: builder.query<
        Array<string>,
        { locationId: string; startDateTime?: string; endDateTime?: string }
      >({
        query: ({ locationId, startDateTime, endDateTime }) =>
          AppointmentRoutes.getAvailableLessonDates(locationId, startDateTime, endDateTime),
        providesTags: ['LessonAppointmentsDates'],
      }),
      getAvailableFittingDates: builder.query<
        Array<string>,
        { locationId: string; startDateTime?: string; endDateTime?: string }
      >({
        query: ({ locationId, startDateTime, endDateTime }) =>
          AppointmentRoutes.getAvailableFittingDates(locationId, startDateTime, endDateTime),
        providesTags: ['ClubFittingAppointmentsDates'],
      }),
      logInWithEmail: builder.mutation<
        void,
        { email: string; locationId: string; bookingUUID?: string }
      >({
        query: (body) => ({
          url: AuthRoutes.loginWithEmail(),
          method: 'POST',
          body,
        }),
        invalidatesTags: ['User'],
      }),
      logInWithProvider: builder.mutation<AuthDto.LoginWithProviderResponse, { idToken: string }>({
        query: (body) => ({
          url: AuthRoutes.loginWithProvider(),
          method: 'POST',
          body,
        }),
        invalidatesTags: ['User'],
      }),
      registerPushNotificationToken: builder.mutation<void, { token: string | null }>({
        query: (body) => ({
          url: AuthRoutes.registerPushToken(),
          method: 'PUT',
          body,
        }),
        invalidatesTags: ['User'],
      }),
      mobileLogInWithEmail: builder.mutation<void, { email: string }>({
        query: (body) => ({
          url: AuthRoutes.loginWithEmail(),
          method: 'POST',
          body,
        }),
        invalidatesTags: ['User'],
      }),
      createAccount: builder.mutation<
        void,
        { registerValues: AuthDto.RegisterDto; bookingUUID?: string }
      >({
        query: ({ registerValues, bookingUUID }) => ({
          url: AuthRoutes.createAccount(),
          method: 'POST',
          body: {
            ...registerValues,
            bookingUUID,
          },
        }),
      }),
      bookAppointment: builder.mutation<
        AppointmentTypes.BookedAppointmentResponse,
        BookAppointmentValues
      >({
        query: ({ locationId, body }) => ({
          url: AppointmentRoutes.bookAppointment(locationId),
          method: 'POST',
          body,
        }),
        invalidatesTags: [
          'SimulatorAppointments',
          'LessonAppointments',
          'FirstLessonAppointments',
          'ClientAppointments',
        ],
      }),
      getAppointment: builder.query<AppointmentTypes.BookedAppointmentResponse, string>({
        query: (appointmentId) => AppointmentRoutes.getAppointmentById(appointmentId),
      }),
      sendWaiverLinksToGuests: builder.mutation<
        void,
        { locationId: string; body: AppointmentTypes.BookingWaiverRequest }
      >({
        query: ({ locationId, body }) => ({
          url: AppointmentRoutes.sendWaiverLinksToGuests(locationId),
          method: 'POST',
          body,
        }),
      }),
      runSyncPrices: builder.mutation<void, { siteId: string }>({
        query: ({ siteId }) => ({
          url: ScheduleRoutes.runSyncPrices(siteId),
          method: 'POST',
          body: {},
        }),
      }),
      verifyUser: builder.query<
        LoginWithVerificationTokenOutputBoundary,
        { token: string; email: string; redirectToUrl?: string }
      >({
        query: ({ token, email, redirectToUrl = '' }) =>
          AuthRoutes.verifyUser(token, encodeURIComponent(email), redirectToUrl),
      }),
      getUserData: builder.query<UserTypes.UserSummary, string>({
        query: (locationId) => AuthRoutes.getUserData(locationId),
        providesTags: ['User'],
      }),
      updateUserData: builder.mutation<void, { updateValues: AuthDto.UpdateDto }>({
        query: ({ updateValues }) => ({
          url: AuthRoutes.updateUserData(),
          method: 'PUT',
          body: updateValues,
        }),
        invalidatesTags: ['User'],
      }),
      updatePaymentDetails: builder.mutation<
        void,
        { locationId: string; body: PaymentDto.ClientDetailsDto }
      >({
        query: ({ locationId, body }) => ({
          url: AuthRoutes.updateBilling(locationId),
          method: 'POST',
          body,
        }),
        invalidatesTags: ['User'],
      }),
      updateUserProvider: builder.mutation<void, AuthDto.CreateOrUpdateUserProvider>({
        query: (values) => ({
          url: AuthRoutes.updateUserProvider(),
          method: 'POST',
          body: values,
        }),
        invalidatesTags: ['User'],
      }),
      refreshAuthToken: builder.mutation<{ accessToken: string; refreshToken: string }, string>({
        query: (refreshToken) => ({
          url: AuthRoutes.refreshToken(),
          method: 'POST',
          body: { refreshToken },
        }),
      }),
      retrieveBookingData: builder.query<AppointmentTypes.AppointmentBooking, string>({
        query: (bookingUUID) => AppointmentRoutes.retrieveBookingData(bookingUUID),
      }),
      saveBookingData: builder.mutation<string, AppointmentTypes.AppointmentBooking>({
        query: (booking) => ({
          url: AppointmentRoutes.storeBookingData(),
          method: 'POST',
          responseHandler: 'text',
          body: booking,
        }),
      }),
      deleteAccount: builder.mutation<void, void>({
        query: () => ({
          url: AuthRoutes.deleteAccount(),
          method: 'DELETE',
        }),
      }),
      checkInForAppointments: builder.mutation<
        void,
        { locationId: string; appointmentIds: number[] }
      >({
        query: ({ locationId, appointmentIds }) => ({
          url: AppointmentRoutes.checkIn(locationId),
          method: 'POST',
          body: { appointmentIds: appointmentIds },
        }),
      }),
      getLocationEventsCalendar: builder.query<Array<CalendarEventTypes.CalendarEvent>, string>({
        query: (locationId) => LocationRoutes.getLocationEventsCalendar(locationId),
      }),
      getFoodItemList: builder.query<
        StoreTypes.ProductResponse,
        { locationId: string; categoryIds?: string; limit?: number; cursor?: string }
      >({
        query: ({ locationId, categoryIds, limit, cursor }) =>
          FoodOrderRoutes.getFoodItemList(locationId, categoryIds, limit, cursor),
        providesTags: ['FoodItemList'],
      }),
      getFoodCategoryList: builder.query<
        StoreTypes.ProductCategoryResponse,
        { locationId: string; startDateTime: string }
      >({
        query: ({ locationId, startDateTime }) =>
          FoodOrderRoutes.getFoodCategoryList(locationId, startDateTime),
      }),
      savePreOrderFoodData: builder.mutation<
        void,
        { locationId: string; body: AppointmentTypes.FoodOrderRequest }
      >({
        query: ({ locationId, body }) => ({
          url: FoodOrderRoutes.storePreOrderFoodData(locationId),
          method: 'PUT',
          body,
        }),
      }),
      sendGtmEvent: builder.mutation<void, object>({
        query: (event) => ({
          url: EventRoutes.sendGtmEvent(),
          method: 'POST',
          body: event,
        }),
      }),
      getAppointmentPointsPricing: builder.mutation<
        { points: number },
        { price: number | undefined }
      >({
        query: (event) => ({
          url: AppointmentRoutes.getAppointmentPointsPricing(),
          method: 'POST',
          body: event,
        }),
      }),
      getAppointmentPointsToEarn: builder.mutation<
        { points: number },
        { appointmentType: string; price: number | undefined; endDateTime: string | undefined }
      >({
        query: (event) => ({
          url: AppointmentRoutes.getAppointmentPointsToEarn(),
          method: 'POST',
          body: event,
        }),
      }),
      getLoyaltyTransactionHistory: builder.query<LoyaltyTypes.LoyaltyCardTransaction[], void>({
        query: AppointmentRoutes.getLoyaltyTransactionHistory,
      }),
      getLoyaltyMyCards: builder.query<LoyaltyTypes.GiftCard[], void>({
        query: AppointmentRoutes.getMyCards,
        providesTags: ['GiftCards'],
      }),
      redeemGiftCard: builder.mutation<
        void,
        {
          rewardId: string;
          points: number;
        }
      >({
        query: (event) => ({
          url: AppointmentRoutes.redeemGiftCard(),
          method: 'POST',
          responseHandler: 'content-type',
          body: event,
        }),
        invalidatesTags: ['GiftCards'],
      }),
      getRewards: builder.query<LoyaltyTypes.Reward[], void>({
        query: AppointmentRoutes.getRewards,
      }),
      postInitialPointsBonus: builder.mutation<void, undefined>({
        query: () => ({
          url: AppointmentRoutes.postInitialPointsBonus(),
          method: 'POST',
          responseHandler: 'content-type',
          body: {
            type: 'initiation_bonus',
          },
        }),
      }),
    }),
  });

export default mboAPI;
