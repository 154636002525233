import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PlayState {
  userMatchFunds?: number;
}

const initialState: PlayState = {};

const playSlice = createSlice({
  name: 'play',
  initialState: initialState,
  reducers: {
    setUserMatchFunds: (state, action: PayloadAction<number>) => {
      state.userMatchFunds = action.payload;
    },
  },
});

export const { setUserMatchFunds } = playSlice.actions;
export default playSlice.reducer;
