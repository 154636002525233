import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import { Box, Button, Fade, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { object, string } from 'yup';

import useCaseSelectSimulatorReservationDate from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectSimulatorReservationDate';
import { WizardStep } from 'domain/entities/WizardStep';
import BookingCalendar from 'infrastructure/components/BookingCalendar';
import { checkIfDateIsInAllowedRange } from 'infrastructure/components/BookingCalendar/helpers';
import ButtonsGroup from 'infrastructure/components/ButtonsGroup';
import { mockPartySizeButtons } from 'infrastructure/components/ButtonsGroup/stories/mockButtons';
import Loader from 'infrastructure/components/Loader';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import SectionTitle from 'infrastructure/components/SectionTitle';
import {
  selectLocation,
  selectLocationDetails,
  selectPartySize,
  selectReservationDate,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { setPartySize, setReservationDate } from 'infrastructure/redux/slices/bookingWizard.slice';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { TextCopyContext } from 'infrastructure/targets/web/contexts/TextCopyContext';
import ErrorBox from 'infrastructure/targets/web/modules/bookingWizard/components/ErrorBox';
import LocationSelect from 'infrastructure/targets/web/modules/bookingWizard/components/LocationSelect';
import { isUAE } from 'infrastructure/targets/web/modules/common/helpers';
import useEventTracking, {
  EventType,
} from 'infrastructure/targets/web/modules/common/hooks/useEventTracking';

import {
  StyledButtonsGroupContainer,
  StyledCalendarContainer,
  StyledFormContainer,
  StyledLoaderContainer,
} from './style';

export const getReservationDateLimitDays = (
  partySize: string | undefined,
  isMember?: boolean,
): number => {
  if (partySize === '7-12') {
    return 10;
  }
  // UAE non-member / not signed in can book 10 days in advance, otherwise the default 14
  if (isUAE() && !isMember) {
    return 10;
  }
  return 14;
};
interface IReservationDateStep {
  stepName: WizardStep;
}
interface IReservationForm {
  reservationDate: string;
  partySize: string;
}

export interface IDayRangeError {
  isError: boolean;
  days: number;
}

const ReservationDateStep: FC<IReservationDateStep> = ({ stepName }) => {
  const { getTextCopy } = useContext(TextCopyContext);

  const {
    nextStep,
    prevStep,
    result: availableDates,
    inProgress: areDatesLoading,
    fetchFurtherAvailableDates,
  } = useCaseSelectSimulatorReservationDate(stepName);

  const today = useRef(new Date());
  const selectedLocationId = useAppSelector(selectLocation);
  const locationDetails = useAppSelector(selectLocationDetails);
  const selectedReservationDate = useAppSelector(selectReservationDate);
  const selectedPartySize = useAppSelector(selectPartySize);
  const [partySizeState, setPartySizeState] = useState(selectedPartySize);
  const user = useAppSelector(selectUserSummary);
  const { sendEvent } = useEventTracking();

  const [showDayRangeError, setDayRangeError] = useState(false);

  const dispatch = useAppDispatch();
  const validationSchema = useMemo(
    () =>
      object().shape({
        reservationDate: string()
          .required()
          .test(
            'empty-check',
            'Please select a date',
            (reservationDate) => reservationDate?.length !== 0,
          ),
        partySize: string()
          .required()
          .test(
            'empty-check',
            'Please select a party size',
            (partySize) => partySize?.length !== 0,
          ),
      }),
    [selectedLocationId],
  );

  const reservationForm = useFormik<IReservationForm>({
    initialValues: {
      reservationDate: `${today.current}`,
      partySize: partySizeState || '',
    },
    validationSchema,
    validateOnBlur: false,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      nextStep(values?.partySize);
      sendEvent(EventType.SelectReservationDate, {
        location: locationDetails?.name,
        reservation_date: format(new Date(values.reservationDate), 'P'),
        party_size: values.partySize,
        utm: localStorage.getItem('utm') ? JSON.parse(localStorage.getItem('utm') as string) : null,
      });
    },
  });

  useEffect(() => {
    if (selectedReservationDate) {
      reservationForm.setFieldValue('reservationDate', selectedReservationDate);
    }
    if (selectedPartySize) {
      setPartySizeState(selectedPartySize);
      reservationForm.setFieldValue('partySize', selectedPartySize);
    }
  }, []);

  const RESERVATION_DATE_LIMIT_DAYS = getReservationDateLimitDays(selectedPartySize, user?.member);

  // Triggers ReservationDate validation when PartySize changes
  useEffect(() => {
    if (selectedPartySize) {
      if (reservationForm.touched.reservationDate && selectedReservationDate) {
        handleDateChange(new Date(selectedReservationDate));
      }
    }
  }, [selectedPartySize]);

  const handleDateChange = (value: Date | null) => {
    if (!value) return;

    if (!checkIfDateIsInAllowedRange(value, RESERVATION_DATE_LIMIT_DAYS)) {
      setDayRangeError(true);
      dispatch(setReservationDate({ reservationDate: `${value}` }));
      reservationForm.setErrors({
        reservationDate: `Please select a date within ${RESERVATION_DATE_LIMIT_DAYS} days`,
      });
      reservationForm.setFieldValue('reservationDate', null);
      reservationForm.setTouched({ reservationDate: true });
    } else {
      setDayRangeError(false);
      dispatch(setReservationDate({ reservationDate: `${value}` }));
      reservationForm.setErrors({
        reservationDate: undefined,
      });
      reservationForm.setFieldValue('reservationDate', value);
      reservationForm.setTouched({ reservationDate: true });
    }
  };

  useEffect(() => {
    dispatch(setReservationDate({ reservationDate: `${today.current}` }));
  }, []);

  const handlePartySizeChange = (value: string) => {
    if (value) {
      dispatch(setPartySize({ partySize: value }));
      reservationForm.setFieldValue('partySize', value);
    }
  };

  const handleMonthChange = (value: Date | null) => {
    fetchFurtherAvailableDates(value);
  };

  return (
    <PageLayout pageTitle={getTextCopy('reserve')} shouldFadeIn>
      <NavigationBar backButtonCallback={prevStep}>
        <LocationSelect selectedSimLocationId={selectedLocationId} />
      </NavigationBar>
      <StyledFormContainer component="form">
        <SectionTitle
          title={'Date'}
          subtitle={'Choose a date for your session. This can always be modified later.'}
          icon={<CalendarMonthOutlinedIcon />}
        />
        {showDayRangeError && (
          <ErrorBox partySize={selectedPartySize as string} days={RESERVATION_DATE_LIMIT_DAYS} />
        )}

        <StyledCalendarContainer>
          <Fade in={areDatesLoading} timeout={400}>
            <StyledLoaderContainer>
              <Loader />
              <Typography variant="body2" color="text.secondary">
                Fetching available days
              </Typography>
            </StyledLoaderContainer>
          </Fade>
          <BookingCalendar
            availableDates={availableDates}
            selectedValue={selectedReservationDate}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
          />
        </StyledCalendarContainer>
        <SectionTitle
          title={'Party Size'}
          subtitle={getTextCopy('partySizeDescription')}
          icon={<PeopleOutlinedIcon />}
        />
        <StyledButtonsGroupContainer>
          <ButtonsGroup
            selectedValue={selectedPartySize}
            shouldStayInOneRow
            buttonList={mockPartySizeButtons}
            onChange={handlePartySizeChange}
          />
        </StyledButtonsGroupContainer>
        <Box sx={{ mt: 7 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => reservationForm.handleSubmit()}
            disabled={!reservationForm.isValid}
            className="nextButton"
            size="large"
            sx={{ float: 'right' }}>
            Next
          </Button>
        </Box>
      </StyledFormContainer>
    </PageLayout>
  );
};

export default ReservationDateStep;
