import { AccessTimeOutlined, GolfCourseOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { date, number, object, string } from 'yup';

import { formatPricing } from 'application/modules/bookingWizard/common/helpers';
import useCaseChooseATimeForLesson, {
  ChooseATimeForm,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseChooseATimeForLesson';
import { WizardStep } from 'domain/entities/WizardStep';
import ButtonsGroup from 'infrastructure/components/ButtonsGroup';
import HorizontalCard, { HorizontalCardType } from 'infrastructure/components/HorizontalCard';
import Loader from 'infrastructure/components/Loader';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import SectionTitle from 'infrastructure/components/SectionTitle';
import {
  selectLessonType,
  selectLocation,
  selectReservationDate,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { setInstructorDetails } from 'infrastructure/redux/slices/bookingWizard.slice';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { ISessionSummary } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep';
import ReservationSummary from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/ReservationSummary';
import { getLessonBookingImage } from 'infrastructure/targets/web/modules/common/imageUrlHelpers';

import {
  StyledAsideContainer,
  StyledButtonsContainer,
  StyledContainer,
  StyledLeftContainer,
  StyledLoaderContainer,
} from './style';

interface IChooseATimeStep {
  stepName: WizardStep;
}

const ChooseATimeStep: FC<IChooseATimeStep> = ({ stepName }) => {
  const selectedLocationId = useAppSelector(selectLocation);
  const reservationDate = useAppSelector(selectReservationDate);
  const selectedLessonType = useAppSelector(selectLessonType);
  const {
    nextStep,
    prevStep,
    selectedInstructor,
    isFirstTimeLesson,
    availableFirstTimeLessonSlots,
    getAvailableLessonSlotButtons,
    lessonTypes,
    inProgress,
    getPricing,
    isPricingLoading,
    pricingError,
    appointentPricing,
    appointmentError,
    areAppointmentsLoading,
    result: firstTimeLessonAppointmentAvailabilities,
  } = useCaseChooseATimeForLesson(stepName);
  const [sessionSummary, setSessionSummary] = useState<ISessionSummary>();
  const [sessionHour, setSessionHour] = useState<string>('');
  const [lessonType, setLessonType] = useState<number | undefined>(
    selectedLessonType ? selectedLessonType : undefined,
  );
  const [chipLabel, setChipLabel] = useState<string>('');
  const dispatch = useAppDispatch();
  const formattedAppointments = formatPricing(appointentPricing);
  const staffFullName =
    selectedInstructor?.staffFirstName &&
    selectedInstructor?.staffLastName &&
    `${selectedInstructor?.staffFirstName} ${selectedInstructor?.staffLastName}`;

  const availableLessonSlots = useCallback(() => {
    if (lessonType) {
      return getAvailableLessonSlotButtons(lessonType);
    }
  }, [lessonType, selectedLessonType, areAppointmentsLoading]);

  const validationSchema = useMemo(
    () =>
      object().shape({
        location: string()
          .required()
          .test('empty-check', 'Location cannot be empty', (fieldValue) =>
            fieldValue ? fieldValue?.length >= 0 : false,
          ),
        reservationDate: date().required(),
        sessionHour: string().required(),
        lessonLength: number().required(),
        sessionTypeId: number().required(),
        staffId: number().required(),
        resourceId: number().required(),
      }),
    [sessionHour, lessonType],
  );

  const chooseATimeForm = useFormik<ChooseATimeForm>({
    initialValues: {
      location: selectedLocationId || '',
      reservationDate: reservationDate && new Date(reservationDate),
      sessionHour: '',
      lessonLength: selectedLessonType,
      sessionTypeId: undefined,
      resourceId: undefined,
      staffId: undefined,
    },
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      nextStep(values);
    },
  });

  const handleSessionHourChange = async (value: string, event: MouseEvent<HTMLElement>) => {
    const selectedStaffId = event?.currentTarget.dataset.additionalvalue;
    const selectedResourceId = event?.currentTarget.dataset.secondaryvalue;
    const selectedResourceIdForFirstTimeLesson = event?.currentTarget.dataset.tertiaryvalue;

    setSessionHour(value);
    await chooseATimeForm.setFieldValue('sessionHour', value);

    if (isFirstTimeLesson) {
      await chooseATimeForm.setFieldValue('staffId', selectedStaffId);

      const selectedSlot = firstTimeLessonAppointmentAvailabilities?.find(
        (slot) => slot.time.toString() === value,
      );
      const selectedInstructor = selectedSlot?.availabilities.find(
        (availability) => availability.staffId === Number(selectedStaffId),
      );
      dispatch(setInstructorDetails({ ...selectedInstructor }));
      await chooseATimeForm.setFieldValue(
        'sessionTypeId',
        Number(availableFirstTimeLessonSlots?.find((slot) => slot.value === value)?.secondaryValue),
      );

      await chooseATimeForm.setFieldValue('resourceId', selectedResourceIdForFirstTimeLesson);
    } else {
      await chooseATimeForm.setFieldValue('resourceId', selectedResourceId);
      await chooseATimeForm.setFieldValue('staffId', selectedInstructor?.staffId);
    }
  };

  const handleLessonTypeChange = async (value: string, event: MouseEvent<HTMLElement>) => {
    const selectedSessionTypeId = event?.currentTarget.dataset.additionalvalue;
    setLessonType(Number(value));
    setSessionHour('unset');
    await chooseATimeForm.setFieldValue('sessionHour', '');
    await chooseATimeForm.setFieldValue('lessonLength', Number(value));
    await chooseATimeForm.setFieldValue('sessionTypeId', Number(selectedSessionTypeId));
  };

  useEffect(() => {
    if (isFirstTimeLesson && firstTimeLessonAppointmentAvailabilities !== undefined) {
      const firstTimeLesson = firstTimeLessonAppointmentAvailabilities[0];
      const { defaultTimeLength } = firstTimeLesson;
      setLessonType(Number(defaultTimeLength));
      chooseATimeForm.setFieldValue('lessonLength', defaultTimeLength);

      setChipLabel(`${defaultTimeLength} Minute Lesson`);
    } else if (selectedInstructor?.staffLevel) {
      setChipLabel(selectedInstructor?.staffLevel);
    }
  }, [firstTimeLessonAppointmentAvailabilities]);

  useEffect(() => {
    chooseATimeForm.setFieldValue(
      'sessionTypeId',
      lessonTypes?.find((lesson) => Number(lesson.value) === selectedLessonType)?.additionalValue,
    );
  }, []);

  useEffect(() => {
    setSessionSummary({
      sessionHour: sessionHour === 'unset' ? '' : sessionHour,
      session: `${lessonType ? lessonType / 60 : ''}`,
      instructor: staffFullName,
    });
  }, [sessionHour, lessonType, selectedInstructor]);

  useEffect(() => {
    if (
      chooseATimeForm.isValid &&
      chooseATimeForm.values.sessionTypeId &&
      chooseATimeForm.values.sessionHour &&
      chooseATimeForm.values.staffId
    ) {
      getPricing(chooseATimeForm.values);
    }
  }, [chooseATimeForm.values, chooseATimeForm.isValid]);

  return (
    <PageLayout pageTitle={'Choose A Time'} shouldFadeIn>
      <NavigationBar backButtonCallback={prevStep} />
      <StyledContainer>
        <StyledLeftContainer>
          <HorizontalCard
            variant={HorizontalCardType.Small}
            chipLabel={chipLabel}
            title={isFirstTimeLesson ? 'Swing Evaluation' : staffFullName || ''}
            imageUrl={
              isFirstTimeLesson
                ? getLessonBookingImage()
                : selectedInstructor?.staffImage || getLessonBookingImage()
            }
          />
          {!isFirstTimeLesson && (
            <>
              <SectionTitle title={'Lesson Type'} icon={<GolfCourseOutlined />} />
              <StyledButtonsContainer>
                <ButtonsGroup
                  buttonList={lessonTypes}
                  onChange={handleLessonTypeChange}
                  selectedValue={selectedLessonType}
                />
              </StyledButtonsContainer>
            </>
          )}
          <SectionTitle title={'Start Time'} icon={<AccessTimeOutlined />} />
          <StyledButtonsContainer>
            {!availableLessonSlots() && !isFirstTimeLesson && (
              <Box
                sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h5">
                  Select Lesson Type to choose your starting time
                </Typography>
              </Box>
            )}
            {appointmentError && !areAppointmentsLoading && (
              <StyledLoaderContainer>
                <Typography variant="body2">
                  Sorry, but there was an error while fetching available apointments, please try
                  again later
                </Typography>
              </StyledLoaderContainer>
            )}
            {inProgress || (areAppointmentsLoading && lessonType && !isFirstTimeLesson) ? (
              <StyledLoaderContainer>
                <Loader />
                <Typography variant="body2">Fetching available appointments</Typography>
              </StyledLoaderContainer>
            ) : (
              <ButtonsGroup
                buttonList={
                  isFirstTimeLesson ? availableFirstTimeLessonSlots : availableLessonSlots()
                }
                selectedValue={sessionHour}
                onChange={handleSessionHourChange}
              />
            )}
          </StyledButtonsContainer>
        </StyledLeftContainer>
        <StyledAsideContainer>
          <ReservationSummary
            sessionSummary={sessionSummary}
            isFormValid={chooseATimeForm.isValid}
            onSubmit={() => chooseATimeForm.handleSubmit()}
            pricing={formattedAppointments.appointments}
            showPricing={lessonType !== undefined}
            isLoading={isPricingLoading}
            error={pricingError}
          />
        </StyledAsideContainer>
      </StyledContainer>
    </PageLayout>
  );
};

export default ChooseATimeStep;
