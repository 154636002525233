import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledCardsContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridAutoFlow: 'column',
  justifyContent: 'center',
  gap: theme.spacing(7),
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    '&:has(:nth-child(4))': {
      gridTemplateRows: 'repeat(2, auto)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(5),
    gridAutoFlow: 'row',
    gap: theme.spacing(5),
  },
}));
