import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { object, string } from 'yup';
import { format, formatDuration } from 'date-fns';
import { BookingWizardFlowTypes } from 'common/infrastructure/modules/appointment/interfaces/AppointmentTypes';

import useCaseSignIn, { SignInError } from 'application/modules/auth/useCases/hooks/useCaseSignIn';
import { WizardStep } from 'domain/entities/WizardStep';
import ContentCard from 'infrastructure/components/ContentCard';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import {
  selectBookingForm,
  selectEmail,
  selectExperienceType,
  selectLocation,
  selectLocationDetails,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { TextCopyContext } from 'infrastructure/targets/web/contexts/TextCopyContext';
import { StyledStrongLink } from 'infrastructure/targets/web/modules/bookingWizard/Steps/CreateAccountStep/style';
import FormikInput from 'infrastructure/targets/web/modules/common/FormikInput';
import { isGenericDuckpin } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/helpers';
import useEventTracking, {
  EventType,
} from 'infrastructure/targets/web/modules/common/hooks/useEventTracking';
import {
  getCurrency,
  mapExperienceTypeToItemDetails,
} from 'infrastructure/targets/web/modules/common/helpers';
import {
  selectPromoCodeData,
  selectTotalAmountToPay,
} from 'infrastructure/redux/slices/payment.selector';

import { StyledSection500Column } from './style';

interface ISignInStep {
  stepName: WizardStep;
}

interface ISignInForm {
  email: string;
}

const SignInStep: FC<ISignInStep> = ({ stepName }) => {
  const { getTextCopy } = useContext(TextCopyContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { nextStep, prevStep, navigateToCreateAccount, error, inProgress } =
    useCaseSignIn(stepName);
  const selectedLocationId = useAppSelector(selectLocation);
  const selectedEmail = useAppSelector(selectEmail);
  const [isDisabled, setIsDisabled] = useState(false);
  const { reservationDate, startTime, sessionLength, partySize } =
    useAppSelector(selectBookingForm);
  const total = useAppSelector(selectTotalAmountToPay);
  const livePromoCodeData = useAppSelector(selectPromoCodeData);
  const experienceType = useAppSelector(selectExperienceType);
  const locationDetails = useAppSelector(selectLocationDetails);
  const { sendEvent } = useEventTracking();
  const validationSchema = useMemo(
    () =>
      object().shape({
        email: string().email('Invalid email').required('Required'),
      }),
    [error, inProgress],
  );

  const signInForm = useFormik<ISignInForm>({
    initialValues: {
      email: selectedEmail || '',
    },
    enableReinitialize: true,
    validationSchema,
    validateOnChange: true,
    validate: () => {
      if (!signInForm.isValid) {
        setIsDisabled(true);
      }
    },
    onSubmit: (values) => {
      if (isDisabled) return;
      if (values?.email) {
        setIsDisabled(true);
        nextStep(values.email, selectedLocationId || '');
        sendEvent(EventType.Login, {
          utm: localStorage.getItem('utm') ? localStorage.getItem('utm') : null
        });
      }
    },
  });

  useEffect(() => {
    if (signInForm.isValid) {
      setIsDisabled(inProgress);
    }
  }, [inProgress, signInForm]);

  useEffect(() => {
    if (error) {
      switch (error) {
        case SignInError.ClientDoesNotExist:
          signInForm.setErrors({
            email: 'User not found. Please create an account or contact us.',
          });
          break;

        case SignInError.InvalidEmail:
          signInForm.setErrors({
            email: 'Please provide a valid email',
          });
          break;

        case SignInError.Unknown:
          signInForm.setErrors({
            email: 'Something went wrong. Please contact support',
          });
          break;

        default:
          break;
      }
    }
  }, [error]);

  useEffect(() => {
    if (!experienceType) {
      return;
    }
    sendEvent(
      EventType.BeginCheckout,
      {
        date: format(new Date(reservationDate as unknown as Date), 'P'),
        party_size: partySize,
        location: locationDetails?.name,
        session_start: startTime && format(new Date(startTime.slice(0, -1)), 'hh:mm aa'),
        session_duration: formatDuration(
          { hours: Number(sessionLength) },
          { format: ['hours'], zero: true },
        ),
        value: total,
        currency: getCurrency(),
        coupon: livePromoCodeData?.code || null,
        checkout_section: 'Step 0: Checkout Started',
        content_group: 'Checkout',
        utm: localStorage.getItem('utm') ? JSON.parse(localStorage.getItem('utm')) : null,
      },
      [
        {
          ...mapExperienceTypeToItemDetails(experienceType as BookingWizardFlowTypes),
          currency: getCurrency(),
          index: 0,
          location_id: 'Product Listing',
          price: total,
          quantity: 1,
        },
      ],
    );
  }, [experienceType]);

  return (
    <PageLayout pageTitle={'Check Out'} shouldFadeIn>
      <NavigationBar backButtonCallback={prevStep} />
      <StyledSection500Column>
        <FormikProvider value={signInForm}>
          <Box component="form" onSubmit={signInForm.handleSubmit} sx={{ width: '100%' }}>
            <ContentCard title={'Sign In'}>
              <Typography variant={'body2'} sx={{ mt: 3, mb: { xs: 2, sm: 4 } }}>
                {isGenericDuckpin()
                  ? getTextCopy('signInDescription2')
                  : getTextCopy('signInDescription')}
              </Typography>
              <FormikInput
                variant="outlined"
                color="secondary"
                type="email"
                name="email"
                noFormLabel
                label={'Email Address'}
                sx={{ width: '100%' }}
              />
              <Typography variant={'body2'} sx={{ mt: 3, mb: { xs: 3, sm: 5 } }}>
                {isGenericDuckpin()
                  ? getTextCopy('createAccountDescription2')
                  : getTextCopy('createAccountDescription')}{' '}
                <StyledStrongLink onClick={navigateToCreateAccount}>
                  {isMobile ? 'Create an account' : 'Create an account to make your reservation'}
                </StyledStrongLink>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className="nextButton"
                type="submit"
                form="email"
                size="large"
                onClick={() => signInForm.handleSubmit()}
                disabled={isDisabled}>
                {inProgress ? (
                  <CircularProgress sx={{ color: 'primary.contrastText' }} />
                ) : (
                  'Send Verification Link'
                )}
              </Button>
            </ContentCard>
          </Box>
        </FormikProvider>
      </StyledSection500Column>
    </PageLayout>
  );
};

export default SignInStep;
