import { FoodOrder } from 'common/infrastructure/modules/appointment/interfaces/AppointmentTypes';

import {
  BookingWizardNavigationHook,
  IBookingWizardNavigationHook,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateBookingWizard';
import {
  IPreOrderFoodHook,
  PreOrderFoodFormValues,
} from 'application/modules/bookingWizard/useCases/hooks/useCasePreOrderFood';
import { WizardStep } from 'domain/entities/WizardStep';
import { useHookInjection } from 'domain/hooks';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import {
  selectConfirmedBooking,
  selectLocation,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { setSquareOrder } from 'infrastructure/redux/slices/bookingWizard.slice';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';

const useSubmitPreOrderFood: IPreOrderFoodHook = () => {
  const dispatch = useAppDispatch();
  const bookingWizardNavigation = useHookInjection<IBookingWizardNavigationHook>(
    BookingWizardNavigationHook,
  );
  const locationId = useAppSelector(selectLocation);
  const confirmedBooking = useAppSelector(selectConfirmedBooking);
  const userData = useAppSelector(selectUserSummary);
  const appointmentId = confirmedBooking?.[0]?.id;

  const [savePreOrderFoodData, { isLoading, isFetching, error, data, isSuccess }] =
    mboAPI.useSavePreOrderFoodDataMutation();

  const handleSaveSquareOrderDataToStore = (orders: FoodOrder[]) => {
    dispatch(setSquareOrder(orders));
  };

  const handleSavePreOrderFoodData = async (values: PreOrderFoodFormValues) => {
    const orders = Object.values(values.order)
      .filter((order) => typeof order !== 'undefined')
      .map((order) => ({
        id: order.productId,
        variation: order.variantId,
        name: order.name,
        quantity: order.quantity,
        price: order.price ?? 0,
      }));

    handleSaveSquareOrderDataToStore(orders);

    if (locationId && appointmentId) {
      await savePreOrderFoodData({
        locationId: locationId,
        body: {
          appointmentId: [appointmentId],
          order: orders,
          notes: values.notes,
        },
      });
    } else if (locationId) {
      if (!userData?.creditCard) {
        bookingWizardNavigation.redirectToStep(WizardStep.paymentDetails);
      } else {
        bookingWizardNavigation.redirectToStep(WizardStep.confirmReservation);
      }
    }
  };

  return {
    inProgress: isLoading || isFetching,
    error: error,
    result: data,
    isSuccess,
    handleSavePreOrderFoodData,
  };
};

export default useSubmitPreOrderFood;
