import { useInjection } from 'inversify-react';

import useCaseGetSimulatorLocations from 'application/modules/bookingWizard/useCases/hooks/useCaseGetSimulatorLocations';
import { InjectableHook } from 'domain/hooks';
import useEffectOnce from 'infrastructure/targets/web/modules/bookingWizard/hooks/useEffectOnce';

export const AppStartupAdapter = Symbol('AppStartupAdapter');

export enum RefreshTokenError {
  Unknown = 'unknown',
}

export interface RefreshTokenResponse {
  refreshToken: string;
}

export type IAppStartupAdapter = InjectableHook<{
  error: any | null;
  wasSuccesful: boolean;
  handleUpdateAppTitle: () => void;
  initializeFacebookPixel: () => void;
  initializeSentry: () => void;
}>;

const useCaseAppStartup = () => {
  const adapter = useInjection<IAppStartupAdapter>(AppStartupAdapter);
  const { error, wasSuccesful, handleUpdateAppTitle, initializeFacebookPixel, initializeSentry } =
    adapter();
  const { fetchLocations } = useCaseGetSimulatorLocations();

  useEffectOnce(() => {
    handleUpdateAppTitle();
    initializeFacebookPixel();
    initializeSentry();
    fetchLocations();
  }, []);

  return {
    error,
    result: wasSuccesful,
  };
};

export default useCaseAppStartup;
