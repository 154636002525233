import { Box } from '@mui/material';
import { Variants } from 'common';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import useCaseChooseSessionLength from 'application/modules/bookingWizard/useCases/hooks/useCaseChooseSessionLength';
import {
  GetSimulatorLocationsAdapter,
  IGetSimulatorLocationsHook,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseGetSimulatorLocations';
import useCaseSelectExperience from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectExperience';
import { WizardStep } from 'domain/entities/WizardStep';
import { useHookInjection } from 'domain/hooks';
import BookingCard from 'infrastructure/components/BookingCard';
import { StyledBookingCardContentSpacer } from 'infrastructure/components/BookingCard/style';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import {
  selectLocation,
  selectLocationDetails,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { setExperienceType } from 'infrastructure/redux/slices/bookingWizard.slice';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { TextCopyContext } from 'infrastructure/targets/web/contexts/TextCopyContext';
import LocationSelect from 'infrastructure/targets/web/modules/bookingWizard/components/LocationSelect';
import {
  isAustralia,
  isUS,
  resolveLocationDuckpinUrl,
} from 'infrastructure/targets/web/modules/common/helpers';
import useEventTracking, {
  EventType,
} from 'infrastructure/targets/web/modules/common/hooks/useEventTracking';
import {
  getBookingImage,
  getBowlingBookingImage,
  getClubFittingImage,
  getLessonBookingImage,
} from 'infrastructure/targets/web/modules/common/imageUrlHelpers';
import useExternalNavigation from 'infrastructure/targets/web/modules/bookingWizard/hooks/useExternalNavigation';

import { StyledCardsContainer } from './style';

interface ISelectExperienceStep {
  stepName: WizardStep;
}
const SelectExperienceStep: FC<ISelectExperienceStep> = ({ stepName }) => {
  const { getTextCopy } = useContext(TextCopyContext);
  const { prevStep, nextStep, preselectLocation, redirectToSelectedExperience } =
    useCaseSelectExperience(stepName);
  const { preselectPartySize, preselectReservationDate } = useCaseChooseSessionLength();
  const { openPageInANewTab } = useExternalNavigation();
  const dispatch = useAppDispatch();
  const selectedLocationId = useAppSelector(selectLocation);
  const selectedLocationDetails = useAppSelector(selectLocationDetails);
  const { sendEvent } = useEventTracking();
  const isGolfVariant = [process.env.VITE_VARIANT].includes(Variants.FIVEIRON);
  const duckpinWebsiteUrl: string | undefined = resolveLocationDuckpinUrl(selectedLocationDetails);

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const getLocationsHook = useHookInjection<IGetSimulatorLocationsHook>(
    GetSimulatorLocationsAdapter,
  );
  const { inProgress: areLocationsFetching } = getLocationsHook;
  const experienceSlug = query?.get('experience');
  const locationSlug = query?.get('location');
  const partySizeSlug = query?.get('partySize');
  const startDateSlug = query?.get('startDate');

  useEffect(() => {
    if (!areLocationsFetching) {
      if (locationSlug) {
        preselectLocation(locationSlug);
      }
      if (partySizeSlug) {
        preselectPartySize(partySizeSlug);
      }
      if (startDateSlug) {
        preselectReservationDate(startDateSlug);
      }
      if (experienceSlug) {
        redirectToSelectedExperience(experienceSlug, partySizeSlug, startDateSlug);
      }
    }
  }, [experienceSlug, locationSlug, partySizeSlug, startDateSlug, areLocationsFetching]);

  const handleCardClick = (experienceType: string) => {
    dispatch(setExperienceType({ experienceType }));
    nextStep(experienceType);
    sendEvent(EventType.SelectExperience, {
      experience: experienceType,
      utm: localStorage.getItem('utm') ? JSON.parse(localStorage.getItem('utm') as string) : null,
    });
  };

  const handleDuckpinCardClick = () => {
    if (!duckpinWebsiteUrl) {
      return;
    }
    openPageInANewTab(duckpinWebsiteUrl);
  };

  return (
    <PageLayout pageTitle={'Book Your Next Visit'} shouldFadeIn>
      <NavigationBar backButtonCallback={prevStep}>
        <LocationSelect selectedSimLocationId={selectedLocationId} />
      </NavigationBar>
      <StyledCardsContainer>
        <BookingCard
          onCardButtonClick={handleCardClick}
          experienceType={isGolfVariant ? 'simulator' : 'bowling'}
          title={getTextCopy('reserve')}
          content={
            <Box component="span">
              {!isAustralia() ? (
                <>
                  {getTextCopy('reserveDescription1')}
                  <StyledBookingCardContentSpacer />
                </>
              ) : null}
              {getTextCopy('reserveDescription2')}
              <StyledBookingCardContentSpacer />
              {getTextCopy('reserveDescription3')}
            </Box>
          }
          imageUrl={getBookingImage()}
        />
        {isGolfVariant && (
          <>
            <BookingCard
              title="Book a Swing Evaluation or Lesson"
              experienceType="lesson"
              onCardButtonClick={handleCardClick}
              content={
                <Box component="span">
                  Each lesson features state-of-the-art metrics and video analysis on TrackMan
                  simulators.
                  <StyledBookingCardContentSpacer />
                  Unique mentoring based on your skill level and goals for the game.
                  <StyledBookingCardContentSpacer />
                  Pricing is based on the level of instructor.
                </Box>
              }
              imageUrl={getLessonBookingImage()}
            />
            {isUS && (
              <BookingCard
                title="Club Fitting"
                experienceType="clubFitting"
                onCardButtonClick={handleCardClick}
                content={
                  <Box component="span">
                    Work with a certified Callaway Club Fitting Specialist and test the latest
                    models from Callaway Golf.
                    <StyledBookingCardContentSpacer />
                    Custom club fitting services are $99 per hour.
                    <StyledBookingCardContentSpacer />
                  </Box>
                }
                imageUrl={getClubFittingImage()}
              />
            )}
            {duckpinWebsiteUrl && (
              <BookingCard
                title="Reserve a Lane"
                experienceType="bowling"
                onCardButtonClick={handleDuckpinCardClick}
                content={
                  <Box component="span">
                    Book by the hour for up to 6 bowlers per lane.
                    <StyledBookingCardContentSpacer />
                    Boldly bowl where no one has bowled before. No bowling shoes required.
                    <StyledBookingCardContentSpacer />
                    Good times are in orbit. Enjoy a curated menu of food and beverage items
                    available to order.
                    <StyledBookingCardContentSpacer />
                  </Box>
                }
                imageUrl={getBowlingBookingImage()}
              />
            )}
          </>
        )}
      </StyledCardsContainer>
    </PageLayout>
  );
};

export default SelectExperienceStep;
