import React from 'react';
import { Box, Link, useMediaQuery, useTheme } from '@mui/material';

import {
  getFanaticsBannerMobile,
  getFanaticsBannerDesktop,
} from 'infrastructure/targets/web/modules/common/imageUrlHelpers';

const url = import.meta.env.VITE_SENTRY_ENV.startsWith('production')
  ? 'https://fanatics.onelink.me/5kut/1nvsyfc4'
  : 'https://fanatics.onelink.me/UTGS/9lgcnvas';

const FanaticsBanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const imageSrc = isMobile ? getFanaticsBannerMobile() : getFanaticsBannerDesktop();

  return (
    <Box>
      <Link href={url} target="_blank" rel="noopener noreferrer">
        <img
          src={imageSrc}
          alt="Fanatics Banner"
          style={{ cursor: 'pointer', width: '100%', borderRadius: '8px' }}
        />
      </Link>
    </Box>
  );
};

export default FanaticsBanner;
