import { WizardStep } from 'domain/entities/WizardStep';

export const bookingWizardInstructorFlowStepsOrder = [
  WizardStep.selectLocation,
  WizardStep.selectExperience,
  WizardStep.selectInstructor,
  WizardStep.chooseLessonTime,
  WizardStep.checkoutSignIn,
  WizardStep.checkoutCheckYourEmail,
  WizardStep.confirmReservation,
  WizardStep.seeYouSoon,
];

export const bookingWizardSimulatorFlowStepsOrder = [
  WizardStep.selectLocation,
  WizardStep.selectExperience,
  WizardStep.reservationDate,
  WizardStep.sessionLength,
  WizardStep.checkoutSignIn,
  WizardStep.checkoutCheckYourEmail,
  WizardStep.confirmReservation,
  WizardStep.seeYouSoon,
];

export const bookingWizardClubFittingFlowStepsOrder = [
  WizardStep.selectLocation,
  WizardStep.selectExperience,
  WizardStep.selectFittingType,
  WizardStep.selectFittingStartTime,
  WizardStep.checkoutSignIn,
  WizardStep.checkoutCheckYourEmail,
  WizardStep.confirmReservation,
  WizardStep.seeYouSoon,
];

export const bookingWizardAuthenticatedSimulatorFlowStepsOrder = [
  WizardStep.selectLocation,
  WizardStep.selectExperience,
  WizardStep.reservationDate,
  WizardStep.sessionLength,
  WizardStep.confirmReservation,
  WizardStep.seeYouSoon,
];

export const bookingWizardAuthenticatedInstructorFlowStepsOrder = [
  WizardStep.selectLocation,
  WizardStep.selectExperience,
  WizardStep.selectInstructor,
  WizardStep.chooseLessonTime,
  WizardStep.confirmReservation,
  WizardStep.seeYouSoon,
];

export const bookingWizardAuthenticatedClubFittingFlowStepsOrder = [
  WizardStep.selectLocation,
  WizardStep.selectExperience,
  WizardStep.selectFittingType,
  WizardStep.selectFittingStartTime,
  WizardStep.confirmReservation,
  WizardStep.seeYouSoon,
];
