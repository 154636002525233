import { Variants } from 'common';

const config = {
  [Variants.DUCKPIN]: {
    reserve: 'Reserve a Lane',
    simulators: 'Lanes',
    reserveDescription1: 'Book by the hour for up to 6 bowlers per lane.',
    reserveDescription2: 'Boldly bowl where no one has bowled before. No bowling shoes required.',
    reserveDescription3:
      'Good times are in orbit. Enjoy a curated menu of food and beverage items available to order.',
    partySizeDescription: 'Duckpin lanes can hold up to 6 people.',
    rental: 'Duckpin Lane Rental',
    rentalDescription1:
      'Experience the thrill of every roll, the rush of every strike, and the buzz of every spare — on state-of-the art duckpin lanes.',
    rentalDescription2: 'Out of this world food and beverage available for purchase.',
    rentalDescription3: '',
    rentalDescription4: '',
    signInDescription:
      'Detroit Duckpin is associated with Five Iron Golf.  Enter the email address associated with your Five Iron Golf account to verify your account and complete your booking.',
    signInDescription2:
      'Enter the email address associated with your Five Iron Golf account to verify your account and complete your booking.',
    createAccountDescription: 'Don’t have a Five Iron Golf account?',
    createAccountDescription2: 'New to Five Iron?',
    offPeakRate: 'Lane Rental Rate',
    errorBox: `Duckpin Lane Rentals can only be booked {{ days }} days in advance, please select another date.`,
    errorBoxSmallEvent: `Small event rentals can only be booked {{ days }} days in advance. Please select another date or choose another party size.`,
    lessonType: 'Session Length',
  },
  [Variants.FIVEIRON]: {
    reserve: 'Reserve a Simulator',
    simulators: 'Simulators',
    clubFittingDescription1:
      "Elevate your equipment with a personalized session guided by Five Iron's expert fitters. Using Trackman technology and Callaway's premium equipment, our team will analyze your performance to recommend the perfect clubs to optimize your game.",
    reserveDescription1: 'Prices range depending on the location and time of day.',
    reserveDescription2:
      'Choose between use of the driving range, course play, or games and competition, all for the same price.',
    reserveDescription3: 'Enjoy a curated menu of food and beverage items available for order.',
    partySizeDescription:
      'Individual simulators can fit up to 6. For larger groups, please contact the location or book multiple appointments.',
    rental: 'Simulator Rental',
    rentalDescription1: 'Use of state-of-the-art clubs',
    rentalDescription2: 'Driving range play',
    rentalDescription3: 'Access to 200+ virtual courses',
    rentalDescription4: 'Food and beverage available for purchase',
    signInDescription:
      'Enter the email address associated with your Five Iron Golf account to verify your account and complete your booking.',
    createAccountDescription: 'New to Five Iron?',
    offPeakRate: 'Off Peak Rate',
    errorBox: `Simulator Rentals can only be booked {{ days }} days in advance, please select another date.`,
    errorBoxSmallEvent: `Small event rentals can only be booked {{ days }} days in advance. Please select another date or choose another party size.`,
    lessonType: 'Lesson Type',
  },
  [Variants.FIVEIRON_MOBILE]: {},
} as { [key in Variants]: { [key: string]: string } };

export default config;
