import React, { FC } from 'react';
import LogoComponent from 'infrastructure/components/PageHeader/components/LogoComponent';
import { StyledTopBar } from './style';

interface HeaderTopBar {
  phoneNumber?: string;
}

const HeaderTopBar: FC<HeaderTopBar> = () => (
  <StyledTopBar sx={{ color: 'secondary.contrastText' }}>
    <LogoComponent />
  </StyledTopBar>
);

export default HeaderTopBar;
