import { useCallback } from 'react';

import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';

import { GTMEvent } from './GTMEvent';

const commonPayload = {
  non_personalized_ads: false,
  event: {},
};

export const useGtmEvent = () => {
  const [sendGtmEventRequest, { isLoading, data, error }] = mboAPI.useSendGtmEventMutation();
  const userSummary = useAppSelector(selectUserSummary);

  const sendGtmEvent = useCallback(
    (eventData: GTMEvent) => {
      const userAddress = {
        first_name: userSummary?.firstName || '',
        last_name: userSummary?.lastName || '',
        phone_number: userSummary?.phone || '',
      };

      try {
        return sendGtmEventRequest({
          ...commonPayload,
          event: {
            event_name: eventData.name,
            user_data: {
              email: userSummary?.email || '',
              address: { ...userAddress },
            },
            ...eventData,
          },
        });
      } catch (error) {
        console.error('Error sending GTM event', error);
      }
    },
    [sendGtmEventRequest, userSummary],
  );

  return { isLoading, data, error, sendGtmEvent };
};
