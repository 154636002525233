import { useInjection } from 'inversify-react';

import { AsyncHookResult, InjectableHook } from 'domain/hooks';

export const GetFittingAvailabilityAdapter = Symbol('GetFittingAvailabilityAdapter');
export type IGetFittingAvailabilityAdapter = InjectableHook<
  AsyncHookResult<Array<Date>, unknown> & {
    fetchFurtherAvailableDates: (newDate: Date | null) => void;
  }
>;

const useCaseSelectFittingReservationDate = () => {
  const adapter = useInjection<IGetFittingAvailabilityAdapter>(GetFittingAvailabilityAdapter);
  const { error, result, inProgress, fetchFurtherAvailableDates } = adapter();

  return {
    fetchFurtherAvailableDates,
    inProgress,
    error,
    result,
  };
};

export default useCaseSelectFittingReservationDate;
