import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, Popover, Typography } from '@mui/material';
import { AppointmentTypes } from 'common';
import { format, formatDuration } from 'date-fns';
import React, { FC, MouseEvent, useMemo, useState } from 'react';

import {
  selectExperienceType,
  selectLocationDetails,
  selectPartySize,
  selectReservationDate,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { ISessionSummary } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep';
import { getAdapterLocale, getCurrency } from 'infrastructure/targets/web/modules/common/helpers';
import useEventTracking, {
  EventType,
} from 'infrastructure/targets/web/modules/common/hooks/useEventTracking';
import { SessionLengthError } from 'application/modules/bookingWizard/useCases/hooks/useCaseChooseSessionLength';
import { AppointmentPricingError } from 'application/modules/bookingWizard/useCases/hooks/useCaseChooseATimeForLesson';
import { ServicePricingError } from 'application/modules/bookingWizard/useCases/hooks/useCaseConfirmReservation';

import CostSummaryContainer from './CostSummaryContainer';
import ReservationSummaryContainer from './ReservationSummaryContainer';
import { StyledSummaryContainer } from './style';

export interface FormattedPricing {
  title: string;
  price: number;
  duration: number;
}
interface IReservationSummary {
  sessionSummary: ISessionSummary | undefined;
  isLoading?: boolean;
  error?: SessionLengthError | AppointmentPricingError | ServicePricingError;
  pricing?: FormattedPricing[];
  onSubmit?: () => void;
  isFormValid?: boolean;
  showPricing?: boolean;
  isSmallEvent?: boolean;
  addOns?: AppointmentTypes.AddOnType[];
}
const ReservationSummary: FC<IReservationSummary> = ({
  sessionSummary,
  isLoading,
  error,
  pricing,
  onSubmit,
  isFormValid,
  showPricing = true,
  isSmallEvent = false,
  addOns = [],
}) => {
  const locationDetails = useAppSelector(selectLocationDetails);
  const reservationDate = useAppSelector(selectReservationDate);
  const flowType = useAppSelector(selectExperienceType);
  const partySize = useAppSelector(selectPartySize);
  const { sendEvent } = useEventTracking();

  const isLessonFlow =
    flowType === AppointmentTypes.BookingWizardFlowTypes.Lesson ||
    flowType === AppointmentTypes.BookingWizardFlowTypes.FirstLesson;

  const total = pricing?.reduce((total, item) => Number(total) + Number(item.price), 0);

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }

    sendEvent(EventType.SelectSessionLength, {
      date: format(new Date(reservationDate as unknown as Date), 'P'),
      party_size: partySize,
      location: locationDetails?.name,
      session_start:
        sessionSummary?.sessionHour &&
        format(new Date(sessionSummary.sessionHour.slice(0, -1)), 'hh:mm aa'),
      session_duration: formatDuration(
        { hours: Number(sessionSummary?.session) },
        { format: ['hours'], zero: true },
      ),
      value: total,
      currency: getCurrency(),
      utm: localStorage.getItem('utm') ? JSON.parse(localStorage.getItem('utm') as string) : null,
    });
  };

  const summaryItems = useMemo(
    () => [
      {
        label: 'Location',
        value: locationDetails?.name,
      },
      {
        label: 'Date',
        value:
          reservationDate &&
          format(new Date(reservationDate), 'EEEE, P', {
            locale: getAdapterLocale(),
          }),
      },
      {
        label: 'Instructor',
        value: isLessonFlow ? sessionSummary?.instructor && sessionSummary?.instructor : undefined,
      },
      {
        label: 'Start Time',
        value:
          sessionSummary?.sessionHour &&
          format(new Date(sessionSummary?.sessionHour.slice(0, -1)), 'hh:mm aa'),
      },
      {
        label: 'Lesson Type',
        value:
          sessionSummary?.lessonType && typeof sessionSummary?.lessonType === 'number'
            ? formatDuration(
                { hours: Number(sessionSummary?.lessonType / 60) },
                { format: ['hours'], zero: true },
              )
            : sessionSummary?.lessonType,
      },
      {
        label: 'Session Length',
        value:
          sessionSummary?.session &&
          formatDuration(
            { hours: Number(sessionSummary?.session) },
            { format: ['hours'], zero: true },
          ),
      },
    ],
    [sessionSummary, reservationDate, locationDetails],
  );

  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | undefined | null>(null);

  const handlePopoverOpen = (event: MouseEvent<SVGSVGElement, MouseEvent> | undefined) => {
    setAnchorEl(event?.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const shouldShowText =
    !sessionSummary || (!sessionSummary?.session && !sessionSummary?.sessionHour);

  return (
    <StyledSummaryContainer>
      <Typography variant="h5">Reservation Summary</Typography>
      <ReservationSummaryContainer summaryItems={summaryItems} />
      {sessionSummary && isFormValid && (
        <>
          {showPricing && (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                <Typography variant="h5">Cost Summary</Typography>
                <InfoOutlined
                  sx={{ color: 'text.primary' }}
                  fontSize="small"
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onMouseEnter={(event) => handlePopoverOpen(event)}
                  onMouseLeave={handlePopoverClose}
                />
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus>
                  <Typography sx={{ p: 2 }}>
                    Our Simulator rental prices vary per hour depending on the time of day.
                  </Typography>
                </Popover>
              </Box>
              <CostSummaryContainer
                pricing={pricing}
                isLoading={isLoading}
                total={total}
                isSmallEvent={isSmallEvent}
                addOns={addOns}
                sessionLength={sessionSummary?.session}
              />
            </>
          )}
        </>
      )}
      <Button
        variant="contained"
        color="primary"
        className="nextButton"
        sx={{ mt: 3, mb: { sm: 0, xs: 5 } }}
        onClick={handleSubmit}
        disabled={isLoading || !!error || !isFormValid}
        size="large">
        {total === 0 ? 'Reserve' : 'Check Out'}
      </Button>
      {shouldShowText ? (
        <Typography
          className="belowNextButton"
          variant="body1"
          color={'text.secondary'}
          sx={{ textAlign: 'center', mt: 2, mb: 2 }}>
          Select your Start Time and Session Length
        </Typography>
      ) : (
        <Typography
          className="belowNextButton"
          fontSize="16px"
          fontWeight={700}
          color={'text.primary'}
          sx={{ textAlign: 'center', mt: 2, mb: 2 }}>
          Best price displayed at checkout
        </Typography>
      )}
    </StyledSummaryContainer>
  );
};
export default ReservationSummary;
