import { Box, Button, Typography } from '@mui/material';
import { AppointmentTypes, Variants } from 'common';
import { BookingWizardFlowTypes } from 'common/infrastructure/modules/appointment/interfaces/AppointmentTypes';
import {
  DailyPromoCodeResponse,
  PromoCodeVerificationResponse,
} from 'common/infrastructure/modules/appointment/interfaces/PromoCodeTypes';
import { formatDuration } from 'date-fns';
import { FormikContextType, useFormikContext } from 'formik';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { VerifyPromoError } from 'application/modules/bookingWizard/useCases/hooks/useCaseConfirmReservation';
import { WizardStep } from 'domain/entities/WizardStep';
import Loader from 'infrastructure/components/Loader';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import {
  selectExperienceType,
  selectLocationDetails,
  selectReservationDate,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { TextCopyContext } from 'infrastructure/targets/web/contexts/TextCopyContext';
import CardTypeIcon from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/CardTypeIcon';
import {
  StyledSummaryDataContainer,
  StyledSummaryDataRow,
  StyledSummaryDivider,
} from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/ReservationSummary/style';
import { StyledLoaderContainer } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/style';
import FormikInput from 'infrastructure/targets/web/modules/common/FormikInput';
import { formatToCurrency } from 'infrastructure/targets/web/modules/common/helpers';

import { FormattedPricing } from '..';
import QuestionnaireContainer from './QuestionnaireContainer';

export enum CostSummaryVariant {
  SummaryWithPromoCode = 'summary-with-promocode',
  RegularSummary = 'regular-summary',
  ReservationComplete = 'reservation-complete',
}

export const SIM_RENTAL_APPT_TITLE = 'Simulator Rental';
export const SIM_RENTAL_KEYWORD = 'Rental';
interface ICostSummaryContainer {
  isLoading?: boolean;
  pricing?: FormattedPricing[] | AppointmentTypes.Appointment[];
  total?: number;
  variant?: CostSummaryVariant;
  isSmallEvent?: boolean;
  addOns?: AppointmentTypes.AddOnType[];
  sessionLength?: string;
  discountAmount?: number;
}

export enum CodeType {
  Fixed = 'fixed',
  Percentage = 'percentage',
}

const MEMBERSHIP_DISCOUNT = 20; // 20% discount for members

// Only use Formik context if available (this is for cases where it's not mandatory)
function useSafeFormikContext<T>(): FormikContextType<T> | null {
  try {
    return useFormikContext<T>(); // Try to get Formik context
  } catch (error) {
    return null; // Return null if Formik context is unavailable
  }
}

const CostSummaryContainer: FC<ICostSummaryContainer> = ({
  isLoading,
  pricing,
  total,
  variant = CostSummaryVariant.RegularSummary,
  isSmallEvent,
  addOns,
  sessionLength,
  discountAmount = 0,
}) => {
  const { getTextCopy } = useContext(TextCopyContext);
  const navigate = useNavigate();
  const user = useAppSelector(selectUserSummary);
  const resDate = useAppSelector(selectReservationDate);
  const location = useAppSelector(selectLocationDetails);
  const experienceType = useAppSelector(selectExperienceType);
  const [promoText, setPromoText] = useState('');
  const [promoCode, setPromoCode] = useState<
    PromoCodeVerificationResponse | DailyPromoCodeResponse | undefined
  >(undefined);
  const [calculatedPrice, setCalculatedPrice] = useState<number | undefined>(undefined);
  const [convenienceFee, setConvenienceFee] = useState<number | undefined>(undefined);
  const [totalDiscount, setTotalDiscount] = useState<number | undefined>(undefined);
  const [promoIsValid, setPromoIsValid] = useState<boolean>(false);
  const [memberDiscountOverride, setMemberDiscountOverride] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formikContext = useSafeFormikContext<any>();

  const isDuckpin = process.env.VITE_VARIANT === Variants.DUCKPIN;

  const [
    verifyPromoCode,
    { data, fulfilledTimeStamp: verifyPromoFulfilledTime, isLoading: isPromoVerificationLoading },
  ] = mboAPI.useLazyVerifyPromoCodeQuery();

  const [getDailyPromoCode, { data: dailyData, fulfilledTimeStamp: dailyFulfilledTimestamp }] =
    mboAPI.useLazyGetDailyPromoCodeQuery();

  const promoTextHelper = (code: string) => {
    if (code === VerifyPromoError.Valid) {
      return 'Success! Your promo code has been applied.';
    }
    if (code === VerifyPromoError.CodeNotActivated)
      return 'This promo code is not valid for this date';
    if (code === VerifyPromoError.CodeDeactivated)
      return 'This promo code is not valid for this date';
    if (code === VerifyPromoError.InvalidDOW)
      return 'This promo code is not valid for this day of the week';
    return 'This promo code is invalid';
  };

  useEffect(() => {
    if (
      variant === CostSummaryVariant.SummaryWithPromoCode &&
      experienceType === BookingWizardFlowTypes.Simulator
    ) {
      if (location?.siteId) {
        setPromoIsValid(false);
        getDailyPromoCode({
          siteId: location?.siteId,
          date: new Date(resDate ?? '').toISOString(),
        });
      }
    }
  }, []);

  useEffect(() => {
    calculateConvenienceFee();
    calculateTotalPrice();
  }, [promoCode, promoCode?.amount, total, user?.member, addOns]);

  useEffect(() => {
    if (verifyPromoFulfilledTime) {
      setPromoText(promoTextHelper((data?.result === 'valid' ? data?.result : data?.error) ?? ''));
      if (data?.result === 'valid' && data?.amount) {
        setPromoCode(data);
        setPromoIsValid(true);
        setMemberDiscountOverride(false);
      } else {
        setPromoIsValid(false);
      }
    }
  }, [verifyPromoFulfilledTime]);

  useEffect(() => {
    if (variant === CostSummaryVariant.SummaryWithPromoCode && dailyData?.amount && formikContext) {
      setPromoCode(dailyData);
      formikContext.setFieldValue('dailyDiscountCode', dailyData?.code);
    }
  }, [dailyFulfilledTimestamp]);

  useEffect(() => {
    if (variant === CostSummaryVariant.SummaryWithPromoCode && formikContext) {
      formikContext.setFieldValue('totalPrice', calculatedPrice);
    }
  }, [calculatedPrice]);

  const handleApplyPromo = (val: string) => {
    if (location?.siteId) {
      verifyPromoCode({
        siteId: location.siteId,
        promoCode: val,
        date: new Date(resDate ?? '').toISOString(),
      });
    }
  };

  const calculateConvenienceFee = () => {
    let convenienceFee = addOns
      ?.filter(Boolean)
      .reduce((total, item) => Number(total) + Number(item.cost), 0);
    // Fee is for 0.5 hour intervals, multiply by number of 0.5 intervals
    convenienceFee = ((convenienceFee ?? 0) * parseFloat(sessionLength ?? '0')) / 0.5;
    setConvenienceFee(convenienceFee);
  };

  const bankersRound = (num: number, decimalPlaces: number) => {
    const d = decimalPlaces || 0;
    const x = num * Math.pow(10, d);
    const r = Math.round(x);
    const br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
    return br / Math.pow(10, d);
  };

  const calculateTotalPrice = (): void => {
    if (!total || total <= 0) {
      setCalculatedPrice(0);
      setTotalDiscount(0);
      return;
    }
    // Return price if no promo attributes are set
    if (!promoCode?.amount || !promoCode?.codeType) {
      setCalculatedPrice(total ?? 0);
      setTotalDiscount(0);
      return;
    }

    // Keep the membership discount
    if (user?.member && promoCode.amount <= MEMBERSHIP_DISCOUNT) {
      setPromoCode(undefined);
      setTotalDiscount(0);
      setCalculatedPrice(total ?? 0);
      if (formikContext) {
        formikContext.setFieldValue('dailyDiscountCode', '');
      }
      return;
    }

    // if a member and current discount is higher, apply discount instead of the membership 20% discount
    if (user?.member && promoCode.amount > MEMBERSHIP_DISCOUNT) {
      const originalPrice = total / (1 - MEMBERSHIP_DISCOUNT / 100); // 20% discount
      const totalDiscount =
        promoCode.codeType === CodeType.Fixed
          ? promoCode.amount ?? 0
          : originalPrice * (promoCode.amount / 100);
      const specialTotal = bankersRound(originalPrice - totalDiscount, 2);
      setCalculatedPrice(specialTotal < 0 ? 0 : specialTotal);
      setTotalDiscount(totalDiscount);
      setMemberDiscountOverride(true);
      return;
    }

    const totalDiscount =
      promoCode.codeType === CodeType.Fixed
        ? promoCode.amount ?? 0
        : total * (promoCode.amount / 100);
    const calculatedTotal = bankersRound(total - totalDiscount, 2);
    setCalculatedPrice(calculatedTotal < 0 ? 0 : calculatedTotal);
    setTotalDiscount(totalDiscount);
    setMemberDiscountOverride(false);
  };

  const totalPrice = isSmallEvent
    ? (calculatedPrice ?? 0) + (convenienceFee ?? 0)
    : calculatedPrice ?? 0;

  const truncatedCardInfo = user?.creditCard?.lastFour
    ? `•••• ${user?.creditCard?.lastFour}`
    : null;
  const combinedPricing = [];
  const rentalRegex = new RegExp(SIM_RENTAL_KEYWORD);

  if (pricing) {
    for (const price of pricing) {
      const isRental = rentalRegex.test(price.title);
      const rentalExists = combinedPricing.findIndex((p) => p.title === SIM_RENTAL_APPT_TITLE);
      const duplicateExists = combinedPricing.findIndex((p) => p.title === price.title);

      if (!isRental && duplicateExists !== -1) {
        combinedPricing[duplicateExists].duration += price.duration;
        combinedPricing[duplicateExists].price =
          Number(price.price) + Number(combinedPricing[duplicateExists].price);
      } else if (isRental && rentalExists !== -1) {
        combinedPricing[rentalExists].duration += price.duration;
        combinedPricing[rentalExists].price =
          Number(price.price) + Number(combinedPricing[rentalExists].price);
      } else if (isRental) {
        combinedPricing.push({ ...price, title: SIM_RENTAL_APPT_TITLE });
      } else {
        combinedPricing.push({ ...price });
      }
    }
  }

  return (
    <>
      {!combinedPricing || !combinedPricing.length || isLoading ? (
        <StyledLoaderContainer>
          <Loader />
        </StyledLoaderContainer>
      ) : (
        <>
          <StyledSummaryDataContainer>
            {combinedPricing?.map((item, idx) => (
              <StyledSummaryDataRow key={`${item?.title}${idx}`}>
                <Typography variant="body2" color={'text.secondary'}>
                  {isDuckpin
                    ? `Lane Rental ${isSmallEvent ? `(2 ${getTextCopy('simulators')})` : ''}`
                    : `${item?.title} ${isSmallEvent ? `(2 ${getTextCopy('simulators')})` : ''}`}
                  <br />
                  {formatDuration(
                    {
                      hours: isSmallEvent
                        ? Number(item?.duration) / 60 / 2
                        : Number(item?.duration) / 60,
                    },
                    { format: ['hours'], zero: true },
                  )}
                </Typography>
                <Typography variant="body2" color={'text.primary'} sx={{ textAlign: 'end' }}>
                  {item?.price === 0 ? (
                    <>
                      {formatToCurrency(item?.price)}
                      <br />
                      {variant !== CostSummaryVariant.SummaryWithPromoCode ? (
                        <span style={{ fontSize: '12px' }}>Membership</span>
                      ) : (
                        <span style={{ fontSize: '12px' }}>Included with Membership</span>
                      )}
                    </>
                  ) : (
                    formatToCurrency(item?.price)
                  )}
                </Typography>
              </StyledSummaryDataRow>
            ))}
            {isSmallEvent && convenienceFee ? (
              <StyledSummaryDataRow>
                <Typography variant="body2" color={'text.secondary'}>
                  Party Convenience Fee
                  <br />
                  {formatDuration(
                    {
                      hours: Number(sessionLength),
                    },
                    { format: ['hours'], zero: true },
                  )}
                </Typography>
                <Typography variant="body2" color={'text.primary'} sx={{ textAlign: 'end' }}>
                  {formatToCurrency(convenienceFee)}
                </Typography>
              </StyledSummaryDataRow>
            ) : null}
            {variant === CostSummaryVariant.ReservationComplete && discountAmount > 0 && (
              <StyledSummaryDataRow>
                <Typography variant="body2" color={'green'}>
                  Discount
                </Typography>
                <Typography variant="body2" color={'green'}>
                  -{formatToCurrency(discountAmount)}
                </Typography>
              </StyledSummaryDataRow>
            )}
          </StyledSummaryDataContainer>
          {variant !== CostSummaryVariant.SummaryWithPromoCode && (
            <>
              <StyledSummaryDataRow>
                <Typography variant="h6">Projected Total</Typography>
                <Typography variant="h6" color={'text.primary'}>
                  {formatToCurrency(totalPrice)}
                </Typography>
              </StyledSummaryDataRow>
            </>
          )}
          {variant === CostSummaryVariant.SummaryWithPromoCode && (
            <>
              <StyledSummaryDivider />

              {process.env.VITE_VARIANT === Variants.FIVEIRON ? <QuestionnaireContainer /> : null}

              <StyledSummaryDivider />

              <FormikInput
                variant="outlined"
                color="secondary"
                type="text"
                name="promoCode"
                noFormLabel
                bottomText={promoText}
                applyButton={true}
                handleApplyPromo={handleApplyPromo}
                isConfirmed={promoIsValid}
                label={'Enter Promo Code'}
                isDisabled={isPromoVerificationLoading || promoIsValid}
                inProgress={isPromoVerificationLoading}
                sx={{
                  mb: 5,
                  width: '100%',
                  '.MuiFormHelperText-root': {
                    color: `${promoIsValid ? 'green' : 'red'} !important`,
                  },
                }}
              />
              <FormikInput
                variant="outlined"
                color="secondary"
                type="text"
                name="notes"
                noFormLabel
                multiline
                rows={4}
                label={'Leave your note here'}
                sx={{ mb: 5, width: '100%' }}
              />
              {promoCode?.amount ? (
                <>
                  <StyledSummaryDataRow>
                    <Typography variant="body1" color="green" fontWeight={700}>
                      {promoIsValid
                        ? 'Promo Code'
                        : memberDiscountOverride
                        ? 'We Found a Better Deal'
                        : "Today's Discount"}
                    </Typography>
                    <Typography variant="body1" color="green" fontWeight={700}>
                      {`-${formatToCurrency(totalDiscount)}`}
                    </Typography>
                  </StyledSummaryDataRow>
                  <Typography variant="body2" color="green">
                    {promoCode.codeType === CodeType.Fixed
                      ? `-$${formatToCurrency(promoCode.amount)} ${
                          memberDiscountOverride ? 'Off Original Non-Member Price' : 'OFF'
                        }`
                      : `-${promoCode.amount}% ${
                          memberDiscountOverride ? 'Off Original Non-Member Price' : 'OFF'
                        }`}
                  </Typography>
                </>
              ) : (
                <></>
              )}
              <StyledSummaryDataRow>
                <Typography variant="h6">Projected Total</Typography>
                <Typography variant="h6" color={'text.primary'}>
                  {totalPrice === 0 ? (
                    <Box sx={{ textAlign: 'end' }}>
                      {formatToCurrency(totalPrice)}
                      <br />
                      <span style={{ fontSize: '12px' }}>Included with Membership</span>
                    </Box>
                  ) : (
                    formatToCurrency(totalPrice)
                  )}
                </Typography>
              </StyledSummaryDataRow>
              <StyledSummaryDataRow sx={{ alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 3 }}>
                  {truncatedCardInfo ? (
                    <>
                      <CardTypeIcon cardType={user?.creditCard?.cardType || 'default'} />
                      <Typography variant="body1">{truncatedCardInfo}</Typography>
                    </>
                  ) : (
                    <Typography variant="body2">Please provide credit card details</Typography>
                  )}
                </Box>
                <Button
                  variant="text"
                  color="primary"
                  size="medium"
                  onClick={() => navigate(`/${WizardStep.paymentDetails}`)}>
                  Update Card
                </Button>
              </StyledSummaryDataRow>

              <StyledSummaryDivider />
            </>
          )}
        </>
      )}
    </>
  );
};
export default CostSummaryContainer;
