import { Box, useTheme } from '@mui/material';
import React from 'react';

import { formatToCurrency } from 'infrastructure/targets/web/modules/common/helpers';

import { StyledPriceBox, StyledRateCard, StyledTitleBox } from './style';

const getFormattedPrice = (price: number) => formatToCurrency(price);

const parseHtml = (text: string) => (
  <span
    dangerouslySetInnerHTML={{
      __html: text
        .split('\n')
        .map((line) => `<p>${line}</p>`)
        .join('')
        .replace(
          /<a\s+(?:[^>]*?\s+)?href="([^"]*)">(.*?)<\/a>/g,
          '<a href="$1" target="_blank" rel="noopener noreferrer" style="color: inherit; text-decoration: none;">$2</a>',
        ),
    }}
  />
);

export const ExtraFeeCard = ({
  title,
  price,
  subtitle,
}: {
  title: string;
  price?: number;
  subtitle?: string;
}) => {
  const theme = useTheme();

  return (
    <StyledRateCard
      sx={{
        marginBottom: '0',
        borderRadius: '8px',
        borderColor: theme.palette.other.outlineBorder,
      }}>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <StyledTitleBox>
          <span style={{ fontWeight: 'bold' }}>{title}</span>
        </StyledTitleBox>
        {price ? (
          <StyledPriceBox>
            <strong>{getFormattedPrice(price)}</strong>
            &nbsp;
            <small>Per Hour</small>
          </StyledPriceBox>
        ) : null}
      </Box>
      {subtitle ? (
        <Box pl={1} pr={1} mt={2}>
          {parseHtml(subtitle)}
        </Box>
      ) : null}
    </StyledRateCard>
  );
};
