import { useEffect } from 'react';

export const useCaptureEmailFromURL = () => {
  useEffect(() => {
    // Get the current URL parameters
    const searchParams = new URLSearchParams(window.location.search);

    // Extract the email parameter
    const email = searchParams.get('email');

    // If email is present, save it in localStorage
    if (email && email !== "undefined") {
      localStorage.setItem('email', email?.replace(/\s/, '+'));
    }
  }, []);
};

export const useCaptureUtmFromURL = () => {
  useEffect(() => {
    // Get the current URL parameters
    const searchParams = new URLSearchParams(window.location.search);

    // Extract the email parameter
    const utm = {
      source: searchParams.get('utm_source'),
      medium: searchParams.get('utm_medium'),
      campaign: searchParams.get('utm_campaign'),
      content: searchParams.get('utm_content'),
      term: searchParams.get('utm_term'),
      campaign_id: searchParams.get('utm_campaign_id'),
    };

    // If email is present, save it in localStorage
    if (utm && utm.campaign_id !== null) {
      localStorage.setItem('utm', JSON.stringify(utm));
    }
  }, []);
};