export const LocationRoutes = {
  getLocations: (siteId?: number) => {
    if (siteId) {
      return `locations?siteId=${siteId}`;
    }
    return `locations`;
  },
  getLocationPricingSchedule: (locationId: string, dayOfMonthIndex: number) =>
    `locations/${locationId}/pricing-schedule/${dayOfMonthIndex}`,
  getLocationEventsCalendar: (locationId: string) => `calendar/events/${locationId}`,
};

export const ScheduleRoutes = {
  runSyncPrices: (siteId: string) => `scheduling/sync-pricing?siteId=${siteId}`,
};

export const AppointmentRoutes = {
  getAvailableSimulatorRentalAppointments: (
    locationId: string,
    partySize: string,
    startDateTime?: string,
    endDateTime?: string,
    email?: string,
  ) => {
    let baseUrl = `appointments/available/simulator/?locationId=${locationId}&partySize=${partySize}`;
    if (startDateTime && endDateTime) {
      baseUrl = `${baseUrl}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    }
    if (email) {
      const encodedEmail = encodeURIComponent(email);
      baseUrl = `${baseUrl}&email=${encodedEmail}`;
    }
    return baseUrl;
  },
  getAvailableSimulatorRentalDates: (
    locationId: string,
    startDateTime?: string,
    endDateTime?: string,
  ) => {
    if (startDateTime && endDateTime) {
      return `appointments/available/dates/simulator?locationId=${locationId}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    }
    return `appointments/available/dates/simulator?locationId=${locationId}`;
  },
  getAvailableLessonAppointments: (
    locationId: string,
    startDateTime?: string,
    endDateTime?: string,
    staffIds?: number,
    email?: string,
  ) => {
    let baseUrl = `appointments/available/lesson/?locationId=${locationId}`;
    if (startDateTime && endDateTime) {
      baseUrl = `${baseUrl}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    }
    if (staffIds) {
      baseUrl = `${baseUrl}&staffIds[]=${staffIds}`;
    }
    if (email) {
      const encodedEmail = encodeURIComponent(email);
      baseUrl = `${baseUrl}&email=${encodedEmail}`;
    }
    return baseUrl;
  },
  getAvailableClubFittingAppointments: (
    locationId: string,
    startDateTime: string,
    endDateTime: string,
    clubFittingId: number,
    email?: string,
  ) => {
    let baseUrl =
      `appointments/available/club-fitting/?locationId=${locationId}&startDateTime=${startDateTime}` +
      `&endDateTime=${endDateTime}&clubFittingId=${clubFittingId}`;
    if (email) {
      const encodedEmail = encodeURIComponent(email);
      baseUrl = `${baseUrl}&email=${encodedEmail}`;
    }
    return baseUrl;
  },
  getAvailableFirstLessonAppointments: (
    locationId: string,
    startDateTime?: string,
    endDateTime?: string,
    email?: string,
  ) => {
    let baseUrl = `appointments/available/first-time-lesson/?locationId=${locationId}`;
    if (startDateTime && endDateTime) {
      baseUrl = `${baseUrl}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    }
    if (email) {
      const encodedEmail = encodeURIComponent(email);
      baseUrl = `${baseUrl}&email=${encodedEmail}`;
    }
    return baseUrl;
  },
  getAvailableLessonDates: (locationId: string, startDateTime?: string, endDateTime?: string) => {
    if (startDateTime && endDateTime) {
      return `appointments/available/dates/lesson?locationId=${locationId}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    }
    return `appointments/available/dates/lesson?locationId=${locationId}`;
  },
  getAvailableFittingDates: (locationId: string, startDateTime?: string, endDateTime?: string) => {
    if (startDateTime && endDateTime) {
      return `appointments/available/dates/club-fitting?locationId=${locationId}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
    }
    return `appointments/available/dates/club-fitting?locationId=${locationId}`;
  },
  getClubFittingTypes: (locationId: string) =>
    `appointments/club-fitting-types?locationId=${locationId}`,
  getAppointmentPricing: () => `appointments/pricing`,
  getAppointmentById: (id: string) => `appointments/${id}`,
  getHourlySimulatorPricing: () => `appointments/simulator/hourly-pricing`,
  bookAppointment: (locationId: string) => `appointments/book/${locationId}`,
  verifyPromoCode: (siteId: string, promoCode: string, date: string) =>
    `appointments/promo/verify?siteId=${siteId}&date=${date}&promocode=${promoCode}`,
  getDailyPromoCode: (siteId: string, date: string) =>
    `appointments/daily-promos/${siteId}?date=${date}`,
  getClientAppointments: () => `appointments/me`,
  getUpcomingAppointments: () => `appointments/upcoming`,
  cancelAppointment: (locationId: string) => `appointments/cancel/${locationId}`,
  storeBookingData: () => `appointments/booking`,
  retrieveBookingData: (bookingUUID: string) => `appointments/booking/${bookingUUID}`,
  getAllInstructors: (locationId: string) => `appointments/instructors?locationId=${locationId}`,
  sendWaiverLinksToGuests: (locationId: string) => `appointments/book/${locationId}/waiver`,
  checkIn: (locationId: string) => `appointments/check-in/${locationId}`,
  getAppointmentPointsPricing: () => `loyalty/appointment-pricing`,
  getAppointmentPointsToEarn: () => `loyalty/appointment-earning-rules`,
  getLoyaltyTransactionHistory: () => `loyalty/customer-loyalty-history`,
  getMyCards: () => `loyalty/gift-cards`,
  redeemGiftCard: () => `loyalty/redeem`,
  getRewards: () => `loyalty/rewards`,
  postInitialPointsBonus: () => `loyalty/custom-event`,
};

export const FoodOrderRoutes = {
  getFoodItemList: (locationId: string, categoryIds?: string, limit?: number, cursor?: string) => {
    let baseUrl = `store/food-items?locationId=${locationId}`;
    if (categoryIds) {
      baseUrl = `${baseUrl}&categoryIds=${categoryIds}`;
    }
    if (limit) {
      baseUrl = `${baseUrl}&limit=${limit}`;
    }
    if (cursor) {
      baseUrl = `${baseUrl}&cursor=${cursor}`;
    }
    return baseUrl;
  },

  getFoodCategoryList: (locationId: string, startDateTime: string) =>
    `store/categories/${locationId}?startDateTime=${startDateTime}`,

  storePreOrderFoodData: (locationId: string) => `appointments/food-order/${locationId}`,
};

export const AuthRoutes = {
  welcomeScreen: () => `welcome`,
  loginWithEmail: () => `auth/login`,
  loginWithProvider: () => `auth/provider/login`,
  registerPushToken: () => `auth/user/push-token`,
  createAccount: () => `auth/register`,
  refreshToken: () => `auth/refresh`,
  deleteAccount: () => `auth/user`,
  getUserData: (locationId?: string) =>
    locationId ? `auth/user?locationId=${locationId}` : `auth/user`,
  updateUserData: () => `auth/user`,
  verifyUser: (token: string, email: string, redirectToUrl: string) =>
    `auth/verify?token=${token}&email=${email}&redirectTo=${redirectToUrl}`,
  updateBilling: (locationId: string) => `auth/update-billing?locationId=${locationId}`,
  updateUserProvider: () => 'auth/user-provider',
};

export const EventRoutes = {
  sendGtmEvent: () => `event/gtm`,
};
