import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppointmentTypes, AvailabilityTypes, LocationTypes } from 'common';
import { BookingWizardFlowTypes } from 'common/infrastructure/modules/appointment/interfaces/AppointmentTypes';
import { LessonAppointmentAvailability } from 'common/infrastructure/modules/appointment/interfaces/AvailabilityTypes';

export type BookingFormAddon = {
  name: string;
  sessionTypeId: number;
  cost?: number;
};

export interface BookingForm {
  location?: string | undefined;
  locationDetails?: LocationTypes.SimulatorLocation | undefined;
  experienceType?: BookingWizardFlowTypes | undefined;
  reservationDate?: string;
  partySize?: string;
  startTime?: string;
  endTime?: string;
  sessionLength?: string;
  sessionTypeId?: number;
  instructor?: string;
  email?: string;
  lessonType?: number;
  appointments?: AppointmentTypes.Appointment[];
  resourceId?: number;
  instructorDetails?: Partial<LessonAppointmentAvailability>;
  confirmedBooking?: AppointmentTypes.BookedAppointmentResponse[];
  leftHanded?: boolean;
  clubRental?: boolean;
  bookingUUID?: string;
  addOns?: BookingFormAddon[];
  clubFittingType?: AvailabilityTypes.ClubFittingType;
  totalPrice?: number;
  redirectTo?: string;
  squareOrder?: AppointmentTypes.FoodOrder[];
}

const bookingFormSlice = createSlice({
  name: 'bookingForm',
  initialState: {},
  reducers: {
    setLocation: (state: BookingForm, action: PayloadAction<string>) => ({
      ...state,
      location: action.payload,
    }),
    setLocationDetails: (
      state: BookingForm,
      action: PayloadAction<LocationTypes.SimulatorLocation | undefined>,
    ) => ({
      ...state,
      locationDetails: action.payload,
    }),
    setExperienceType: (state: BookingForm, { payload }) => ({
      ...state,
      experienceType: payload.experienceType,
    }),
    setInstructor: (state: BookingForm, { payload }) => ({
      ...state,
      instructor: payload.instructor,
    }),
    setReservationDate: (state: BookingForm, { payload }) => ({
      ...state,
      reservationDate: payload.reservationDate !== 'Invalid Date' ? payload.reservationDate : '',
    }),
    setPartySize: (state: BookingForm, { payload }) => ({
      ...state,
      partySize: payload.partySize,
    }),
    setEmail: (state: BookingForm, { payload }) => ({
      ...state,
      email: payload.email,
    }),
    setStartTime: (state: BookingForm, { payload }) => ({
      ...state,
      startTime: payload.startTime,
    }),
    setLessonType: (state: BookingForm, { payload }) => ({
      ...state,
      lessonType: payload.lessonType,
    }),
    setStartTimeAndSessionLength: (state: BookingForm, { payload }) => ({
      ...state,
      startTime: payload.startTime,
      endTime: payload.endTime,
      sessionLength: payload.sessionLength,
    }),
    setAppointments: (state: BookingForm, { payload }) => ({
      ...state,
      appointments: payload.appointments,
    }),
    setResourceId: (state: BookingForm, { payload }) => ({
      ...state,
      resourceId: payload.resourceId,
    }),
    setInstructorDetails: (state: BookingForm, { payload }) => ({
      ...state,
      instructorDetails: payload,
    }),
    setBookingWizardData: (state: BookingForm, { payload }) => ({
      ...state,
      ...payload,
    }),
    setConfirmedBooking: (state: BookingForm, { payload }) => ({
      ...state,
      confirmedBooking: payload,
    }),
    setBookingUUID: (state: BookingForm, { payload }) => ({
      ...state,
      bookingUUID: payload.bookingUUID,
    }),
    setLeftHanded: (state: BookingForm, { payload }) => ({
      ...state,
      leftHanded: payload.leftHanded,
    }),
    setClubRental: (state: BookingForm, { payload }) => ({
      ...state,
      clubRental: payload.clubRental,
    }),
    setAddons: (state: BookingForm, { payload }) => ({
      ...state,
      addOns: payload ? [payload] : [],
    }),
    setTotalPrice: (state: BookingForm, { payload }) => ({
      ...state,
      totalPrice: payload,
    }),
    setRedirectTo: (state: BookingForm, { payload }) => ({
      ...state,
      redirectTo: payload,
    }),
    setSquareOrder: (state: BookingForm, action) => ({
      ...state,
      squareOrder: action.payload,
    }),
    setClubFittingType: (state: BookingForm, action) => ({
      ...state,
      clubFittingType: action.payload.clubFittingType,
    }),
    clearBookingData: () => ({}),
  },
});

export const {
  setLocation,
  setTotalPrice,
  setExperienceType,
  setReservationDate,
  setPartySize,
  setInstructor,
  setEmail,
  setStartTimeAndSessionLength,
  setAppointments,
  setLocationDetails,
  setStartTime,
  setResourceId,
  setLessonType,
  setInstructorDetails,
  setBookingWizardData,
  setConfirmedBooking,
  setBookingUUID,
  setLeftHanded,
  setClubRental,
  setAddons,
  setRedirectTo,
  setSquareOrder,
  setClubFittingType,
  clearBookingData,
} = bookingFormSlice.actions;
export default bookingFormSlice.reducer;
