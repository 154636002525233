import { BookingWizardFlowTypes } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';
import { useNavigate } from 'react-router';

import { IBookingWizardNavigationHook } from 'application/modules/bookingWizard/useCases/hooks/useCaseNavigateBookingWizard';
import { WizardStep } from 'domain/entities/WizardStep';
import { selectAccessToken } from 'infrastructure/redux/slices/auth.selector';
import { selectExperienceType } from 'infrastructure/redux/slices/bookingWizard.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import {
  bookingWizardAuthenticatedInstructorFlowStepsOrder,
  bookingWizardAuthenticatedSimulatorFlowStepsOrder,
  bookingWizardAuthenticatedClubFittingFlowStepsOrder,
  bookingWizardInstructorFlowStepsOrder,
  bookingWizardSimulatorFlowStepsOrder,
  bookingWizardClubFittingFlowStepsOrder,
} from 'infrastructure/targets/web/modules/bookingWizard/navigation/BookingWizardStepsOrder';

const GetWizardStepsOrder = () => {
  const experienceType = useAppSelector(selectExperienceType);
  const accessToken = useAppSelector(selectAccessToken);
  const isSignedIn = !!accessToken;

  if (!experienceType) {
    return bookingWizardSimulatorFlowStepsOrder;
  }
  switch (experienceType) {
    case BookingWizardFlowTypes.Lesson:
      if (isSignedIn) {
        return bookingWizardAuthenticatedInstructorFlowStepsOrder;
      } else {
        return bookingWizardInstructorFlowStepsOrder;
      }
    case BookingWizardFlowTypes.FirstLesson:
      if (isSignedIn) {
        return bookingWizardAuthenticatedInstructorFlowStepsOrder;
      } else {
        return bookingWizardInstructorFlowStepsOrder;
      }
    case BookingWizardFlowTypes.Simulator:
    case BookingWizardFlowTypes.Bowling:
      if (isSignedIn) {
        return bookingWizardAuthenticatedSimulatorFlowStepsOrder;
      } else {
        return bookingWizardSimulatorFlowStepsOrder;
      }
    case BookingWizardFlowTypes.ClubFitting:
      if (isSignedIn) {
        return bookingWizardAuthenticatedClubFittingFlowStepsOrder;
      } else {
        return bookingWizardClubFittingFlowStepsOrder;
      }
  }
};

const useBookingWizardNavigation: IBookingWizardNavigationHook = () => {
  const navigate = useNavigate();
  const bookingWizardStepsOrder = GetWizardStepsOrder() || [];

  const handleRedirectToNextStep = (currentStep: WizardStep) => {
    const currentStepIndex = bookingWizardStepsOrder.findIndex((step) => step === currentStep);
    const nextStepIndex = currentStepIndex + 1;
    if (currentStepIndex === -1 || !bookingWizardStepsOrder[nextStepIndex]) {
      return;
    }
    navigate(`/${bookingWizardStepsOrder[nextStepIndex]}`);
  };

  const handleRedirectToPrevStep = (currentStep: WizardStep) => {
    const currentStepIndex = bookingWizardStepsOrder.findIndex((step) => step === currentStep);
    const prevStepIndex = currentStepIndex - 1;
    if (currentStepIndex === -1 || !bookingWizardStepsOrder[prevStepIndex]) {
      return;
    }
    navigate(`/${bookingWizardStepsOrder[prevStepIndex]}`);
  };
  return {
    redirectToNextStep: handleRedirectToNextStep,
    redirectToPrevStep: handleRedirectToPrevStep,
    redirectToStep: (step: WizardStep) => navigate(`/${step}`),
  };
};

export default useBookingWizardNavigation;
