import { IGetFoodItemListHook } from 'application/modules/bookingWizard/useCases/hooks/useCaseGetFoodItemList';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import {
  selectAppointments,
  selectConfirmedBooking,
  selectLocation,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';

const useGetFoodItemList: IGetFoodItemListHook = () => {
  const locationId = useAppSelector(selectLocation);
  const confirmedBooking = useAppSelector(selectConfirmedBooking);
  const selectedAppointments = useAppSelector(selectAppointments);
  const startDateTime =
    confirmedBooking?.[0]?.startDateTime || selectedAppointments?.[0].startDateTime;

  const [getFoodItemList, { data, isLoading, error, isFetching }] =
    mboAPI.useLazyGetFoodItemListQuery();

  const [
    getFoodCategoryList,
    {
      data: foodCategoryList,
      error: foodCategoryListError,
      isLoading: foodCategoryListLoading,
      isFetching: foodCategoryListFetching,
    },
  ] = mboAPI.useLazyGetFoodCategoryListQuery();

  const handleFetchFoodItemList = async (
    categoryId: string,
    pagination?: {
      limit?: number;
      cursor?: string | null;
    },
  ) => {
    if (locationId) {
      await getFoodItemList({
        locationId: locationId,
        categoryIds: categoryId,
        limit: pagination?.limit,
        cursor: pagination?.cursor ?? undefined,
      });
    }
  };

  const handleFetchCategoryList = async () => {
    if (locationId && startDateTime) {
      await getFoodCategoryList({ locationId, startDateTime });
    }
  };

  return {
    inProgress: isLoading || isFetching,
    error: error,
    result: data,
    fetchFoodItemList: handleFetchFoodItemList,
    foodCategoryListInProgress: foodCategoryListLoading || foodCategoryListFetching,
    foodCategoryListError: foodCategoryListError,
    fetchFoodCategoryList: handleFetchCategoryList,
    foodCategoryList,
  };
};

export default useGetFoodItemList;
