import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { AppointmentTypes } from 'common';
import { addDays, endOfDay, format, formatDuration, isPast, isToday, startOfDay } from 'date-fns';
import { useEffect, useMemo } from 'react';

import { formatPricing, isValidDateString } from 'application/modules/bookingWizard/common/helpers';
import {
  ISessionLengthAdapter,
  SessionLengthError,
  SessionLengthForm,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseChooseSessionLength';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import { selectAccessToken } from 'infrastructure/redux/slices/auth.selector';
import {
  selectLocation,
  selectPartySize,
  selectReservationDate,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import {
  setAddons,
  setAppointments,
  setPartySize,
  setReservationDate,
  setStartTimeAndSessionLength,
} from 'infrastructure/redux/slices/bookingWizard.slice';
import { setTotalAmountToPay } from 'infrastructure/redux/slices/payment.slice';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { setUserSummary } from 'infrastructure/redux/slices/user.slice';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';

function getError(
  error: FetchBaseQueryError | SerializedError | undefined,
): SessionLengthError | undefined {
  if (!error) {
    return undefined;
  }
  return SessionLengthError.Unknown;
}

type Availability = {
  staffLetter: string;
  staffId: number;
  durations: { duration: number; cost: number }[];
};

function getAvailableStaffIds(
  availabilities: Availability[],
  duration: number,
): { staffIds: number[]; cost: number } {
  const availableStaffIds: number[] = [];
  let cost = 0;
  for (const availability of availabilities) {
    const matchDuration = availability.durations.find((d) => d.duration === duration);
    if (matchDuration) {
      availableStaffIds.push(availability.staffId);
      cost = matchDuration.cost;
    }
    // We only need 2 first available staff ids
    if (availableStaffIds.length === 2) {
      break;
    }
  }
  return { staffIds: availableStaffIds, cost };
}

const useSessionLength: ISessionLengthAdapter = () => {
  const selectedLocationId = useAppSelector(selectLocation);
  const selectedPartySize = useAppSelector(selectPartySize);
  const reservationDate = useAppSelector(selectReservationDate);
  const user = useAppSelector(selectUserSummary);
  const accessToken = useAppSelector(selectAccessToken);
  const dispatch = useAppDispatch();
  const isReservationDateToday = reservationDate && isToday(new Date(reservationDate));

  const endDate = reservationDate && endOfDay(new Date(reservationDate));
  const startDate =
    reservationDate &&
    (isReservationDateToday ? new Date(reservationDate) : startOfDay(new Date(reservationDate)));

  const [getPrices, result] = mboAPI.useGetAppointmentPricingMutation();
  const [getWholeDayPrices, wholeDayPrices] = mboAPI.useGetHourlySimulatorPricingMutation({
    fixedCacheKey: 'SIM_RATES',
  });
  const [getUser] = mboAPI.useLazyGetUserDataQuery();

  const isDateValid = useMemo(() => {
    if (!reservationDate || Number.isNaN(new Date(reservationDate).getTime())) return;

    return isValidDateString(reservationDate) && !isPast(addDays(new Date(reservationDate), 1));
  }, [reservationDate]);

  const [getAvailabilities, { data, isLoading, error, isFetching, refetch }] =
    mboAPI.useLazyGetAvailableSimulatorRentalAppointmentsQuery();
  const handleRefetch = () => {
    refetch();
  };

  const handleSaveAppointmentDataToStore = (values: SessionLengthForm) => {
    const pricings = formatPricing(result.data);
    dispatch(
      setStartTimeAndSessionLength({
        startTime: values.startDateTime,
        endTime: values.endDateTime,
        sessionLength: values.sessionLength,
      }),
    );
    dispatch(setAppointments({ appointments: pricings.appointments }));
    const total = pricings.appointments?.reduce(
      (total, item) => Number(total) + Number(item.price),
      0,
    );
    dispatch(setAddons(pricings.addOn));
    dispatch(setTotalAmountToPay({ total }));
  };

  const handleSavePartySizeToStore = (selectedPartySize: string) => {
    dispatch(setPartySize({ partySize: selectedPartySize }));
  };

  const handleSaveReservationDateToStore = (selectedReservationDate: Date) => {
    dispatch(setReservationDate({ reservationDate: selectedReservationDate }));
  };

  const buttonAvailabilities = [30, 60, 90, 120, 150, 180, 210, 240];

  const availableTimeSlots = useMemo(
    () =>
      data
        ?.map((item) => {
          const availabilities = item.availabilities;
          const sectionTitle = item.time.toString();
          const buttonData = {
            sectionTitle: format(new Date(sectionTitle.slice(0, -1)), 'hh:mm aa'),
            sectionValue: `${item.time}`,
            buttonList: buttonAvailabilities.map((button) => ({
              value: button,
              title: formatDuration({ hours: button / 60 }, { format: ['hours'], zero: true }),
              additionalValue: '',
              isDisabled: true,
              availableForSmallEvents:
                getAvailableStaffIds(availabilities, button).staffIds.length > 1,
              price: 0,
            })),
          };

          buttonData.buttonList.forEach((button) => {
            const availability = getAvailableStaffIds(availabilities, button.value);
            if (availability.staffIds.length > 0) {
              button.additionalValue = availability.staffIds.join(',');
              button.isDisabled = false;
              button.price = availability.cost;
            }
          });

          return buttonData;
        })
        .filter((slot) => !slot.buttonList.every((button) => button.isDisabled)),
    [data],
  );

  const handleGetPricing = (values: AppointmentTypes.AppointmentPricingRequest) =>
    getPrices({
      ...values,
      email: user?.email,
    });

  const handleGetWholeDayPricing = (values: AppointmentTypes.HourlySimulatorPricingRequestDto) =>
    getWholeDayPrices({
      locationId: values.locationId,
      email: values.email,
    });

  const handleGetUserData = async (newLocationId: string) => {
    if (accessToken) {
      const payload = await getUser(newLocationId).unwrap();
      dispatch(setUserSummary(payload));
    }
  };

  useEffect(() => {
    if (
      selectedLocationId &&
      startDate &&
      endDate &&
      selectedPartySize &&
      isDateValid &&
      process.env.VITE_IS_WEB
    ) {
      getAvailabilities({
        locationId: selectedLocationId || '',
        startDateTime: startDate && isDateValid ? format(startDate, 'yyyy-MM-dd') : undefined,
        endDateTime: endDate && isDateValid ? format(endDate, 'yyyy-MM-dd') : undefined,
        email: user?.email ?? undefined,
        partySize: selectedPartySize,
      });
    }
  }, [
    selectedLocationId,
    startDate?.toString(),
    endDate?.toString(),
    isDateValid,
    selectedPartySize,
  ]);

  return {
    error: getError(result.error),
    result: result.data,
    isSuccess: result.isSuccess,
    inProgress: result.isLoading,
    areAppointmentsLoading: isLoading || isFetching,
    appointmentError: getError(error),
    availableTimeSlots,
    getPricing: handleGetPricing,
    getAvailabilities,
    refetchAvailabilities: handleRefetch,
    getWholeDayPricing: handleGetWholeDayPricing,
    wholeDayPrices: wholeDayPrices.data,
    userData: user,
    handleGetUserData,
    areWholePricesLoading: wholeDayPrices.isLoading,
    handleSaveAppointmentDataToStore,
    handleSavePartySizeToStore,
    handleSaveReservationDateToStore,
  };
};

export default useSessionLength;
