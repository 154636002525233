import { InfoOutlined } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Close';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { Variants } from 'common';
import { differenceInHours } from 'date-fns';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';

import { AppointmentTypes } from 'domain/entities/ManageBooking';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { ReactComponent as FiveIronLogo } from 'infrastructure/targets/web/assets/images/fiveiron/logo.svg';
import { TextCopyContext } from 'infrastructure/targets/web/contexts/TextCopyContext';
import {
  CANCEL_WINDOW_HRS,
  formatPhoneNumber,
  formatToCurrency,
  isUAE,
  Locales,
  Region,
  RegionType,
} from 'infrastructure/targets/web/modules/common/helpers';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';

const IMAGE_SIZE = 180;

interface BookingDetails {
  locationId: string;
  image: string;
  title: string;
  bookingDate: string;
  rawTime: Date;
  location: string;
  instructor: string;
  startTime: string;
  sessionLength: number;
  price: number | null;
  paidWithPoints?: boolean;
  bookingIds: number[];
  type: string;
}

interface BookingCardProps {
  bookingDetails: BookingDetails;
  onCancel?: (id: number[], locId: string) => void;
  onGetPhoneNumber: (locationId: string) => string;
}

const Points: FC<{ value: number }> = ({ value }) => {
  const formattedPointsValue = new Intl.NumberFormat(Locales[Region], {
    maximumFractionDigits: 0,
  }).format(value);
  return (
    <Stack
      direction="row"
      flex="none"
      gap={1}
      width="auto"
      alignItems="center"
      justifyContent={{ xs: 'start', sm: 'end' }}>
      <FiveIronLogo style={{ flex: 'none', height: '24px', width: 'auto' }} />

      <Typography variant={'h4'} fontWeight={900}>
        {formattedPointsValue}
      </Typography>
    </Stack>
  );
};

const BookingCard: React.FC<BookingCardProps> = ({
  bookingDetails,
  onCancel,
  onGetPhoneNumber,
}) => {
  const t = useTranslationPrefix('my_bookings.cancellation');
  const { getTextCopy } = useContext(TextCopyContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const user = useAppSelector(selectUserSummary);
  const {
    locationId,
    image,
    title,
    rawTime,
    bookingDate,
    location,
    instructor,
    startTime,
    sessionLength,
    price,
    paidWithPoints,
    type,
  } = bookingDetails;

  const handleCardClick = () => {
    // TODO - add booking details redirect
  };

  const isRental = useMemo(
    () =>
      type === AppointmentTypes.RENTAL ||
      type === AppointmentTypes.PARTY_RENTAL ||
      type === AppointmentTypes.BOWLING ||
      type === AppointmentTypes.PARTY_BOWLING ||
      type === AppointmentTypes.CLUB_FITTING,
    [type],
  );

  const shouldShowContactModal = () =>
    !isRental && differenceInHours(rawTime, new Date()) < CANCEL_WINDOW_HRS;

  const isLateCancellation = useMemo(
    () => differenceInHours(rawTime, new Date()) < CANCEL_WINDOW_HRS && !user?.member,
    [rawTime, user?.member],
  );

  useEffect(() => {
    if (locationId) {
      const phoneNumber = onGetPhoneNumber(locationId);
      setPhoneNumber(phoneNumber);
    }
  }, [locationId]);

  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (shouldShowContactModal()) {
      setShowContactModal(true);
    } else {
      setOpen(true);
    }
  };

  const handleOnCancelClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen(false);
    onCancel?.(bookingDetails.bookingIds, locationId);
  };

  const modalCancellationContent = useMemo(() => {
    if (isLateCancellation) {
      return isUAE() ? (
        t('confirm_description_late_fee_percentage', { context: 'web' })
      ) : Region === RegionType.USA ? (
        <Trans
          i18nKey="my_bookings.cancellation.confirm_description_late_fee_amount_us"
          context="web"
          components={{ bold: <strong /> }}
        />
      ) : (
        <Trans
          i18nKey="my_bookings.cancellation.confirm_description_late_fee_amount"
          context="web"
          components={{ bold: <strong /> }}
        />
      );
    }

    return Region === RegionType.USA ? t('confirm_description_us', { context: 'web' }) : '';
  }, [isLateCancellation, t]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        border: `1px solid ${theme.palette.other.outlineBorder}`,
        p: 4,
        pb: 0,
        cursor: 'pointer',
        boxShadow: 'none',
        minHeight: isMobile ? 'auto' : IMAGE_SIZE + 32,
      }}
      onClick={handleCardClick}
      elevation={0}>
      {!isMobile ? (
        <CardMedia
          component="img"
          sx={{
            width: isMobile ? '100%' : IMAGE_SIZE,
            height: IMAGE_SIZE,
            borderRadius: 1,
            objectFit: 'cover',
          }}
          image={image}
          alt={title}
        />
      ) : (
        <Stack direction={'row'} alignItems={'center'} gap={4}>
          <CardMedia
            component="img"
            sx={{
              width: IMAGE_SIZE / 3,
              height: IMAGE_SIZE / 3,
              borderRadius: 1,
              objectFit: 'cover',
            }}
            image={image}
            alt={title}
          />

          <Box>
            <Typography component="h5" variant="h6" fontWeight={900}>
              {title}
            </Typography>

            <Typography
              sx={{
                color:
                  process.env.VITE_VARIANT === Variants.DUCKPIN
                    ? `${theme.palette.success.main}!important`
                    : `text.primary!important`,
              }}
              fontWeight={'bold'}>
              {bookingDate}
            </Typography>
          </Box>
        </Stack>
      )}

      <CardContent
        sx={{ flex: 1, pt: isMobile ? 2 : 0, pb: '8px!important', pl: isMobile ? 2 : 4, pr: 0 }}>
        <Grid container spacing={2} height={'100%'}>
          <Grid item xs={isMobile ? 12 : 8}>
            {!isMobile && (
              <>
                <Typography component="h5" variant="h5" fontWeight={900}>
                  {title}
                </Typography>
                <Typography
                  variant={'subtitle1'}
                  sx={{
                    color:
                      process.env.VITE_VARIANT === Variants.DUCKPIN
                        ? `${theme.palette.success.main}!important`
                        : `${theme.palette.primary.main}!important`,
                  }}
                  fontWeight={'bold'}>
                  {bookingDate}
                </Typography>
              </>
            )}

            <Typography variant={'subtitle1'}>Location: {location}</Typography>

            {instructor && !isRental && (
              <Typography variant={'subtitle1'}>Instructor: {instructor}</Typography>
            )}

            {!isRental && (
              <Typography variant={'subtitle1'}>
                {getTextCopy('lessonType')}: {sessionLength}{' '}
                {type === AppointmentTypes.GROUP
                  ? 'Hour (Small Group)'
                  : sessionLength > 1
                  ? 'Hours'
                  : 'Hour'}
              </Typography>
            )}

            <Typography variant={'subtitle1'}>Start Time: {startTime}</Typography>

            {isRental && (
              <Typography variant={'subtitle1'}>
                Session Length: {sessionLength} {sessionLength > 1 ? 'Hours' : 'Hour'}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 4}
            gap={2}
            container
            direction={'column'}
            justifyContent={'space-between'}>
            {!price ? (
              <Box textAlign={isMobile ? 'start' : 'end'}>
                <Tooltip
                  enterTouchDelay={0}
                  title="Pricing information unavailable for bookings made through the MBO app or over the phone. Please contact us for more information.">
                  <Button
                    size="small"
                    sx={{
                      pl: 0,
                      pr: 0,
                      '&.MuiButtonBase-root:hover': {
                        bgcolor: 'transparent',
                      },
                      fontSize: '16px',
                    }}>
                    Pricing Unavailable
                    <InfoOutlined
                      fontSize="small"
                      sx={{ ml: '3px', color: theme.palette.text.disabled }}
                    />
                  </Button>
                </Tooltip>
              </Box>
            ) : !paidWithPoints ? (
              <Typography variant={'h4'} textAlign={isMobile ? 'start' : 'end'} fontWeight={900}>
                {formatToCurrency(Number(price))}
              </Typography>
            ) : (
              <Points value={price} />
            )}

            <Button
              sx={{ width: isMobile ? '100%' : 180, alignSelf: 'flex-end' }}
              startIcon={<CancelIcon color={'error'} />}
              variant={'outlined'}
              color={'error'}
              onClick={handleCancelClick}>
              Cancel Booking
            </Button>
          </Grid>
        </Grid>
      </CardContent>

      {/* Cancellation modal */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {isLateCancellation
            ? 'Late Cancellation'
            : 'Are you sure you want to cancel this booking?'}
        </DialogTitle>
        <DialogContent
          sx={{
            whiteSpace: 'pre-line',
          }}>
          {modalCancellationContent}
        </DialogContent>

        <DialogActions sx={{ p: 4 }}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleOnCancelClick} color={'error'} variant={'contained'}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showContactModal} onClose={() => setShowContactModal(false)}>
        <DialogTitle>Please Contact Customer Service</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your booking is scheduled to start within the next {CANCEL_WINDOW_HRS} hours. Please
            contact customer support by calling phone number {formatPhoneNumber(phoneNumber)}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowContactModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default BookingCard;
