export enum WizardStep {
  selectLocation = 'select-location',
  selectExperience = 'select-experience',
  reservationDate = 'reservation-date',
  sessionLength = 'session-length',
  selectInstructor = 'select-instructor',
  chooseLessonTime = 'choose-lesson-time',
  checkoutSignIn = 'sign-in',
  checkoutCheckYourEmail = 'check-your-email',
  createAccount = 'create-account',
  paymentDetails = 'payment-details',
  confirmReservation = 'confirm-reservation',
  seeYouSoon = 'see-you-soon',
  pricing = 'pricing',
  preOrderFood = 'pre-order-food',
  selectFittingType = 'select-fitting-type',
  selectFittingStartTime = 'select-fitting-start-time',
}
