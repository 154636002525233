import { Box, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { Variants } from 'common';
import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';

import { isValidDateString } from 'application/modules/bookingWizard/common/helpers';
import useCaseChooseSessionLength from 'application/modules/bookingWizard/useCases/hooks/useCaseChooseSessionLength';
import { WizardStep } from 'domain/entities/WizardStep';
import {
  selectLocation,
  selectLocationDetails,
  selectPartySize,
  selectReservationDate,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import { ExtraFeeCard } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/ExtraFeeCard/ExtraFeeCard';
import { StyledSummaryDivider } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/ReservationSummary/style';

import { StyledPricingSummaryContainer } from './style';

const insertAnchor = (url: string, text?: string) =>
  `<a href="${url}" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: inherit;">${
    text ? text : url
  }</a>`;

const PricingSummary = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedLocationId = useAppSelector(selectLocation);
  const user = useAppSelector(selectUserSummary);
  const selectedReservationDate = useAppSelector(selectReservationDate);
  const selectedLocationDetails = useAppSelector(selectLocationDetails);
  const promotions = selectedLocationDetails?.promotions?.includes('OneDollarPromo');
  const specialPromo = selectedLocationDetails?.promotions?.includes('SpecialPromo');
  const gameDaySinglePromo = selectedLocationDetails?.promotions?.includes('GameDaySinglePromo');
  const gameDayMultiPromo = selectedLocationDetails?.promotions?.includes('GameDayMultiPromo');
  const multisportSim = selectedLocationDetails?.promotions?.includes('MultisportSim');
  const majorWatchParty = selectedLocationDetails?.promotions?.includes('MajorWatchParty');
  const selectedPartySize = useAppSelector(selectPartySize);
  const isSmallEvent = selectedPartySize === '7-12';
  const { getWholeDayPricing, wholeDayPrices, areWholePricesLoading } = useCaseChooseSessionLength(
    WizardStep.sessionLength,
  );

  const startDate = useMemo(
    () => selectedReservationDate && format(new Date(selectedReservationDate), 'yyyy-MM-dd'),
    [selectedReservationDate],
  );

  useEffect(() => {
    if (!selectedReservationDate || !selectedLocationId) {
      return;
    }

    if (startDate && selectedReservationDate && isValidDateString(selectedReservationDate)) {
      getWholeDayPricing({
        email: user?.email,
        locationId: selectedLocationId,
      });
    }
  }, [selectedReservationDate, selectedLocationId]);

  return areWholePricesLoading ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {!isMobile && <StyledSummaryDivider sx={{ my: 6 }} />}
      <Skeleton animation="wave" sx={{ width: '100%', height: '112px' }} />
      <Skeleton animation="wave" sx={{ width: '100%', height: '112px' }} />
    </Box>
  ) : (
    <Box sx={{ mb: { xs: 10, md: 0 } }}>
      {!isMobile && <StyledSummaryDivider sx={{ my: 6 }} />}
      <StyledPricingSummaryContainer>
        {isSmallEvent && wholeDayPrices?.partyRentalFee ? (
          <>
            <ExtraFeeCard
              title="Party Convenience Fee"
              price={2 * wholeDayPrices?.partyRentalFee || 0} // Multiple by 2 as default fee is for 0.5 hour intervals.
              subtitle={
                process.env.VITE_VARIANT === Variants.DUCKPIN
                  ? 'Small event rentals guarantee two lanes next to each other.'
                  : 'Small event rentals guarantee two simulators next to each other.'
              }
            />
            <br />
          </>
        ) : null}
        {!isSmallEvent && process.env.VITE_VARIANT === Variants.FIVEIRON && promotions && (
          <>
            <ExtraFeeCard
              title="$1 Sim Nights"
              price={1}
              subtitle={
                // eslint-disable-next-line max-len
                'Enjoy $1 per hour simulator rentals with promo code DollarSim! Input the promo code on the booking page and pricing will be applied at time of checkout in the location. \nBaltimore, Cleveland, DC, Detroit, Pittsburgh, Shelby: 7pm-Close, Wed-Sat \nSeattle: 7pm-Close, Thur-Sat \nBoston, Chicago, Cincinnati, NYC, Philadelphia: 10pm-Close, Fri-Sat'
              }
            />
            <br />
          </>
        )}
        {!isSmallEvent && process.env.VITE_VARIANT === Variants.FIVEIRON && specialPromo && (
          <>
            <ExtraFeeCard
              title="Fall Special!"
              subtitle={
                'All Sims $20/person for an hour! M-F after 7pm and ALL DAY Saturday and Sunday.\n*Discount will be applied in the venue at the time of checkout.'
              }
            />
            <br />
          </>
        )}

        {!isSmallEvent && process.env.VITE_VARIANT === Variants.FIVEIRON && gameDaySinglePromo && (
          <>
            <ExtraFeeCard
              title="Big Game Blitz!"
              subtitle={
                // eslint-disable-next-line max-len
                `Looking to book for the big game? Click here to get a 5 hour private bay rental, bottomless snacks, and your first pitcher free on February 9th.\n${insertAnchor(
                  'https://fiveirongolf.com/promos/',
                )}`
              }
            />
            <br />
          </>
        )}

        {!isSmallEvent && process.env.VITE_VARIANT === Variants.FIVEIRON && gameDayMultiPromo && (
          <>
            <ExtraFeeCard
              title="Big Game Blitz!"
              subtitle={
                // eslint-disable-next-line max-len
                `Looking to book for the big game? Click here to get a 5 hour private bay rental, bottomless snacks, and your first pitcher free on February 9th.\n${insertAnchor(
                  'https://fiveirongolf.com/promos/',
                  // eslint-disable-next-line max-len
                )}\nMultisport sim capabilities are available on a first come, first serve basis and cannot be guaranteed. If you would like to be placed in a Multisport sim, please request it in the notes field and we will do our best to accommodate your request.`
              }
            />
            <br />
          </>
        )}

        {!isSmallEvent && process.env.VITE_VARIANT === Variants.FIVEIRON && multisportSim && (
          <>
            <ExtraFeeCard
              title="Multisport Simulators"
              subtitle={
                // eslint-disable-next-line max-len
                'Multisport sim capabilities are available on a first come, first serve basis and cannot be guaranteed. If you would like to be placed in a Multisport sim, please request it in the notes field and we will do our best to accommodate your request.'
              }
            />
            <br />
          </>
        )}

        {!isSmallEvent && process.env.VITE_VARIANT === Variants.FIVEIRON && majorWatchParty && (
          <>
            <ExtraFeeCard
              title="Book your Major Watch Party now for Sunday, April 13th"
              subtitle={
                // eslint-disable-next-line max-len
                `Experience the back 9 on Sunday like never before. See who gets crowned champion with a private bay, Augusta-inspired bites, and picture-in-picture. Click ${insertAnchor(
                  'https://fiveirongolf.com/major-watch-parties/',
                  'HERE',
                )} to reserve your sim now, limited availability.\n${insertAnchor(
                  'https://fiveirongolf.com/major-watch-parties/',
                  'fiveirongolf.com/major-watch-parties',
                )}`
              }
            />
            <br />
          </>
        )}
      </StyledPricingSummaryContainer>
    </Box>
  );
};
export default PricingSummary;
