import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { AvailabilityTypes } from 'common';
import {
  BookingWizardFlowTypes,
  SimulatorResourceAvailability,
} from 'common/infrastructure/modules/appointment/interfaces/AppointmentTypes';
import { add, addDays, endOfDay, format, isPast, isToday, startOfDay } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { formatPricing, isValidDateString } from 'application/modules/bookingWizard/common/helpers';
import {
  ISelectClubFittingAdapter,
  SelectClubFittingError,
} from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectClubFitting';
import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';
import {
  selectClubFittingType,
  selectExperienceType,
  selectLocation,
  selectReservationDate,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import {
  setAddons,
  setAppointments,
  setClubFittingType,
  setExperienceType,
  setPartySize,
  setResourceId,
  setStartTimeAndSessionLength,
} from 'infrastructure/redux/slices/bookingWizard.slice';
import { setTotalAmountToPay } from 'infrastructure/redux/slices/payment.slice';
import { selectUserSummary } from 'infrastructure/redux/slices/user.selector';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { SelectFittingStartTimeForm } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SelectFittingStartTimeStep';
function getFetchClubFittingError(
  error: FetchBaseQueryError | SerializedError | undefined,
): SelectClubFittingError | undefined {
  if (!error) {
    return undefined;
  }
  return SelectClubFittingError.Unknown;
}

const useSelectClubFitting: ISelectClubFittingAdapter = () => {
  const dispatch = useAppDispatch();
  const selectedLocationId = useAppSelector(selectLocation);
  const reservationDate = useAppSelector(selectReservationDate);
  const flowType = useAppSelector(selectExperienceType);
  const clubFittingType = useAppSelector(selectClubFittingType);
  const isReservationDateToday = reservationDate && isToday(new Date(reservationDate));
  const startDate =
    reservationDate &&
    (isReservationDateToday ? new Date(reservationDate) : startOfDay(new Date(reservationDate)));
  const endDate = reservationDate && endOfDay(new Date(reservationDate));
  const user = useAppSelector(selectUserSummary);
  const [getPricing, pricing] = mboAPI.useGetAppointmentPricingMutation();

  const isDateValid = useMemo(() => {
    if (!reservationDate || Number.isNaN(new Date(reservationDate).getTime())) return;

    return isValidDateString(reservationDate) && !isPast(addDays(new Date(reservationDate), 1));
  }, [reservationDate]);

  const [availabilities, setAvailabilities] = useState<
    (SimulatorResourceAvailability & { staffId: number })[]
  >([]);

  const [
    SelectClubFitting,
    {
      data: clubFittings,
      isLoading: areClubFittingsLoading,
      error: clubFittingsError,
      isFetching: areClubFittingsFetching,
    },
  ] = mboAPI.useLazyGetAvailableClubFittingAppointmentsQuery();

  const [
    SelectClubFittingType,
    {
      data: clubFittingTypes,
      isLoading: areClubFittingTypesLoading,
      error: clubFittingTypesError,
      isFetching: areClubFittingTypesFetching,
    },
  ] = mboAPI.useLazyGetClubFittingTypesQuery();

  const setFlowTypeToClubFitting = () => {
    dispatch(setExperienceType({ experienceType: BookingWizardFlowTypes.ClubFitting }));
  };

  const clearPartySize = () => {
    dispatch(setPartySize({ partySize: undefined }));
  };

  const saveSelectedClubFittingTypeToStore = (
    clubFittingType: AvailabilityTypes.ClubFittingType,
  ) => {
    dispatch(setClubFittingType({ clubFittingType: clubFittingType }));
  };

  const saveAppointmentToStore = (values: SelectFittingStartTimeForm) => {
    const resourceId = Number(values?.resourceId);
    const formattedAppointments = formatPricing(pricing.data);
    const startDateTime = values?.sessionHour;
    const endDateTime = add(new Date(startDateTime), {
      minutes: formattedAppointments.appointments[0].duration, // Currently all club fittings are 1 hour long
    }).toISOString();

    dispatch(
      setStartTimeAndSessionLength({
        startTime: startDateTime,
        endTime: endDateTime,
        sessionLength: formattedAppointments.appointments[0].duration / 60,
      }),
    );
    dispatch(setResourceId({ resourceId }));
    dispatch(
      setAppointments({
        appointments: formattedAppointments.appointments?.map((value) => ({
          ...value,
          resourceId,
        })),
      }),
    );
    dispatch(setAddons(formattedAppointments.addOn));
    const total = formattedAppointments?.appointments.reduce(
      (total, item) => Number(total) + Number(item.price),
      0,
    );
    dispatch(setTotalAmountToPay({ total }));
  };

  const getAvailabilities = () => {
    const availabilities = clubFittings
      ? clubFittings.flatMap((r) =>
          r.services.flatMap((s) =>
            s.availabilities.map((a) => ({
              ...a,
              staffId: r.staffId,
            })),
          ),
        )
      : [];

    const uniqueByTime = [];
    const seenTimes = new Set();

    for (const item of availabilities) {
      if (!seenTimes.has(item.time)) {
        seenTimes.add(item.time);
        uniqueByTime.push(item);
      }
    }
    return uniqueByTime;
  };

  useEffect(() => {
    const availabilities = getAvailabilities();
    setAvailabilities(availabilities);
  }, [clubFittings]);

  const handleGetPricing = (values: SelectFittingStartTimeForm) => {
    const startDateTime = values?.sessionHour;
    const endDateTime = add(new Date(startDateTime), {
      minutes: Number(clubFittings?.[0]?.services?.[0].defaultTimeLength),
    }).toISOString();
    const staffId = values.staffId;
    if (selectedLocationId && staffId) {
      getPricing({
        email: user?.email,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        locationId: selectedLocationId,
        sessionTypeId: clubFittings?.[0].services?.[0].sessionTypeId,
        staffIds: [staffId],
      });
    }
  };

  const getAvailableLessonSlotButtons = () => {
    const slotButtons = availabilities
      .slice()
      .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
      .map((availability) => {
        const time = availability?.time.toString();
        return {
          value: time,
          title: format(new Date(time?.slice(0, -1)), 'hh:mm aa'),
          secondaryValue: availability?.resourceId,
          additionalValue: availability?.staffId,
        };
      });
    return slotButtons;
  };

  useEffect(() => {
    if (selectedLocationId && isDateValid && startDate && endDate && clubFittingType) {
      SelectClubFitting({
        locationId: selectedLocationId,
        clubFittingId: clubFittingType.id,
        startDateTime: format(startDate, 'yyyy-MM-dd'),
        endDateTime: format(endDate, 'yyyy-MM-dd'),
        email: user?.email,
      });
    }
  }, [
    selectedLocationId,
    startDate?.toString(),
    endDate?.toString(),
    clubFittingType,
    isDateValid,
    user,
  ]);

  useEffect(() => {
    if (selectedLocationId) {
      SelectClubFittingType(selectedLocationId);
    }
  }, [selectedLocationId]);

  return {
    result: clubFittings,
    inProgress: areClubFittingsLoading || areClubFittingsFetching,
    error: getFetchClubFittingError(clubFittingsError),
    saveAppointmentToStore,
    saveSelectedClubFittingTypeToStore,
    setFlowTypeToClubFitting,
    clearPartySize,
    SelectClubFitting,
    handleGetPricing,
    pricing,
    availableDates: Array.from(new Set(availabilities.map((a) => a.time))),
    flowType,
    availabilities,
    getAvailableLessonSlotButtons,
    SelectClubFittingType,
    clubFittingTypes,
    clubFittingTypesInProgress: areClubFittingTypesLoading || areClubFittingTypesFetching,
    clubFittingTypesError,
    userData: user,
  };
};

export default useSelectClubFitting;
