import * as Sentry from '@sentry/react';
import { useState } from 'react';

import { IAppStartupAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseAppStartup';

const useAppStartup: IAppStartupAdapter = () => {
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState<any | null>(null);

  const initializeSentry = async () => {
    try {
      const isProd = import.meta.env.VITE_SENTRY_ENV.startsWith('production');
      const isLocal = import.meta.env.VITE_SENTRY_ENV === 'local';
      if (!isLocal) {
        Sentry.init({
          environment: import.meta.env.VITE_SENTRY_ENV,
          dsn: import.meta.env.VITE_SENTRY_DSN,
          integrations: [
            Sentry.replayIntegration(),
            Sentry.browserTracingIntegration(),
            Sentry.browserProfilingIntegration(),
          ],
          maxValueLength: 1000,
          tracesSampleRate: isProd ? 0.1 : 0.3,
          replaysSessionSampleRate: 0.01,
          replaysOnErrorSampleRate: 0.1,
          tracePropagationTargets: [new RegExp(`/^${import.meta.env.VITE_BACKEND_API_BASE_URL}`)],
          beforeSend(event, hint) {
            const error = hint.originalException as any;
            // Try to extract statusCode from either the error or an inner response object
            const statusCode =
              error?.status ||
              error?.statusCode ||
              error?.response?.status ||
              error?.response?.statusCode;

            if (statusCode && [401, 403].includes(statusCode)) {
              return null;
            }
            return event;
          },
        });
      }
      setInitialized(true);
    } catch (error) {
      setInitialized(false);
      setError(error);
      console.error(error);
    }
  };

  const initializeFacebookPixel = async () => {
    try {
      const { default: ReactPixel } = await import('react-facebook-pixel');
      ReactPixel.init(import.meta.env.VITE_META_PIXEL, undefined, {
        autoConfig: true,
        debug: true,
      });
      ReactPixel.pageView();
      ReactPixel.track('PageView');
      setInitialized(true);
    } catch (error) {
      setInitialized(false);
      setError(error);
      console.error(error);
    }
  };

  const handleUpdateAppTitle = () => {
    try {
      document.title = import.meta.env.VITE_APP_TITLE;
      setInitialized(true);
    } catch (error) {
      setInitialized(false);
      setError(error);
      console.error(error);
    }
  };

  return {
    error: error,
    wasSuccesful: initialized,
    handleUpdateAppTitle,
    initializeFacebookPixel,
    initializeSentry,
  };
};

export default useAppStartup;
